@use "../mixins/breakpoints";

.inquiries-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 68px;

  @include breakpoints.media-breakpoint-up(md) {
    flex-direction: row;
    max-width: 1120px;
    margin: 0 auto;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    padding-bottom: 136px;
  }
}

.inquiries-container > h2 {
  text-align: center;

  @include breakpoints.media-breakpoint-up(md) {
    width: 40%;
    text-align: left;
  }
}

.inquiries {
  display: flex;
}

.inquiry {
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
}

.inquiry:nth-child(odd) {
  padding-left: 0;
}

.inquiry:nth-child(even) {
  padding-right: 0;
}