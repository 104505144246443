@use "../global/variables";
@use "../mixins";

//
// Site menu component
//
@include mixins.media-breakpoint-down(md) {
    html.menu-active {
        overflow: hidden;
    }

    .site-menu {
        @include mixins.fixed(top left);
        @include mixins.font-smoothing();
        @include mixins.size(100vw, 100vh);
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 136px 68px;
        transition: visibility 0ms linear;
        transition-delay: variables.$mobile-menu-delay-out;
        visibility: hidden;
        z-index: 5;

        .menu-active & {
            visibility: visible;
            transition-delay: variables.$mobile-menu-delay-in;
        }
    }

    .site-menu-container {
        @include mixins.relative();
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .site-menu-item {
        opacity: 0;
        transform: translate(40px, 0);
        transition-duration: variables.$mobile-menu-item-duration;
        transition-property: opacity, transform;
        transition-timing-function: ease-in;

        .menu-active & {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    .site-menu-item + .site-menu-item {
        margin-top: 5px;
    }

    .site-menu-link {
        color: variables.$white;
        font-size: 16px;
        font-weight: variables.$font-weight-medium;
        line-height: 35px;

        &:hover,
        &:focus {
            color: variables.$white;
            text-decoration: none;
        }
    }

    @for $i from 1 through variables.$mobile-menu-item-count {
        .site-menu-item:nth-child(#{$i}) {
            transition-delay: ($i - 1) * variables.$mobile-menu-item-delay;

            .menu-active & {
                transition-delay: ($i - 1) * variables.$mobile-menu-item-delay + 250ms;
            }
        }
    }

    .site-menu-item-btn {
        display: none;
        margin-top: auto;
        opacity: 0;
        transform: translate(0, 20px);
        transition-delay: 375ms;
        transition-duration: variables.$mobile-menu-item-duration;
        transition-property: opacity, transform;
        transition-timing-function: ease-in;

        .menu-active & {
            opacity: 1;
            transform: translate(0, 0);
            transition-delay: 375ms;
        }
    }

    // The max height/width * sin-1(45deg) to account for the rotation
    $mobile-bg-size: "calc(max(100vw, 100vh) * 1.414214)";

    .site-menu-mobile-bg {
        @include mixins.absolute(top 50% left 50%);
        backdrop-filter: blur(3px);
        background: rgba(variables.$gray-700, 0.85);
        height: #{$mobile-bg-size};
        transform: translate(-50%, -50%) rotate(-45deg) scaleY(0);
        transition-delay: variables.$mobile-menu-bg-delay-out;
        transition-duration: variables.$mobile-menu-bg-duration;
        transition-property: transform;
        transition-timing-function: ease-in-out;
        width: #{$mobile-bg-size};

        .menu-active & {
            transform: translate(-50%, -50%) rotate(-45deg) scaleY(1);
            transition-delay: variables.$mobile-menu-bg-delay-in;
        }
    }
}

@include mixins.media-breakpoint-down(sm) {
    .site-menu-item-btn {
        display: block;
    }

    .site-menu-item-copy {
        margin-top: 36px;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .site-menu {
        align-items: center;
        display: flex;
    }

    .site-menu-container {
        @include mixins.relative();
        align-items: center;
        display: flex;
        margin-right: 15px;
    }

    .site-menu-item {
        padding: 0 15px;
    }

    .site-menu-item.is-active {
        font-weight: variables.$font-weight-medium;
    }

    .site-menu-link {
        font-size: 15px;
        line-height: 20px;
        white-space: nowrap;
    }

    .site-menu-link,
    .site-menu-link:hover,
    .site-menu-link:focus {
        color: inherit;
        text-decoration: none;
    }

    .site-menu-item-btn {
        font-size: 15px;
        line-height: 20px;
        padding: 9px 12px;
        width: 160px;
    }

    .site-menu-mobile-bg,
    .site-menu-item-copy {
        display: none;
    }

    .site-menu-underline {
        @include mixins.absolute(top 100%);
        @include mixins.size(200px, 2px);
        background: currentColor;
        border-radius: 1px;
        transition-duration: 175ms;
        transition-property: left, width;
        transition-timing-function: ease-in;
    }
}
