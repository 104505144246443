@use "../global/variables";
@use "../mixins";

//
// Site footer component
//
.site-footer {
  @include mixins.font-smoothing();
  background: variables.$gray-700;
  color: variables.$white;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding-top: 68px;
}

.site-footer-title {
  color: variables.$white;
  font-weight: variables.$font-weight-medium;
}

.site-footer-link {
  color: variables.$white;

  &:hover,
  &:focus {
    color: variables.$white;
  }
}

.site-footer-logo {
  display: none;
  width: 250px;
}

.site-footer-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.site-footer-content-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.site-footer-content-nav {
  margin-top: 68px;
  text-align: center;
  width: 100%;
}

.site-footer-content-nav-left,
.site-footer-content-nav-centre,
.site-footer-content-nav-right {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.site-footer-content-nav-centre,
.site-footer-content-nav-right {
  margin-top: 24px;
}

.site-footer-content-btn {
  @include mixins.font-smoothing(off);
  padding-top: 14px;
  padding-bottom: 14px;
  width: 290px;
}

.site-footer-content-social {
  margin: 68px auto 0;
  width: 30px;
}

.site-footer-australian {
  margin-top: 68px;
  text-align: center;
}

.site-footer-australian svg {
  width: 72px;
}

.site-footer-copyright {
  color: variables.$gray-200;
  border-top: 1px solid variables.$gray-300;
  margin-top: 36px;
  padding-top: 36px;
  padding-bottom: 68px;
  text-align: center;
}

@include mixins.media-breakpoint-up(md) {
  .site-footer-content-nav {
    display: grid;
    grid-gap: variables.$grid-gutter-width;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
  }

  .site-footer-content-nav-left,
  .site-footer-content-nav-centre,
  .site-footer-content-nav-right {
    align-items: flex-start;
    row-gap: 10px;
  }

  .site-footer-content-nav-centre,
  .site-footer-content-nav-right {
    margin-top: 0;
  }

  .site-footer-content-social {
    display: grid;
    grid-gap: variables.$grid-gutter-width;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    width: 100%;
  }

  .site-footer-content-social a {
    @include mixins.size(32px);
    display: block;
    grid-column-start: 3;
  }

  .site-footer-australian {
    text-align: left;
  }

  .site-footer-copyright {
    text-align: left;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .site-footer-logo {
    display: block;
  }

  .site-footer-content {
    align-items: flex-start;
    display: grid;
    grid-gap: variables.$grid-gutter-width;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 68px;
  }

  .site-footer-content-btn {
    font-size: 15px;
    grid-column: auto / span 4;
    margin-left: auto;
    padding-top: 9px;
    padding-bottom: 9px;
    width: 190px;
    order: 2;
  }

  .site-footer-content-inner {
    grid-column: auto / span 8;
    grid-template-columns: repeat(5, 1fr);
    order: 1;
  }

  .site-footer-content-nav {
    margin-top: 0;
  }

  .site-footer-australian {
    text-align: right;
  }

  .site-footer-australian svg {
    width: 92px;
  }
}
