@use "../global/variables";
@use "../mixins/breakpoints";

//
// News article section
//
.article-back {
    margin-bottom: variables.$spacer-1 * 0.5;
}

.article-hero {
    margin-bottom: variables.$spacer-1;
}

.article-hero-title {
    line-height: 1.2;
    margin-bottom: variables.$spacer-1;
}

.article-hero-intro {
    font-size: 1.375rem;
    line-height: 1.25;
    margin-bottom: variables.$spacer-1 * 0.75;
}

.article-hero-intro > :last-child {
    margin-bottom: 0;
}

.article-hero-share {
    align-items: center;
    display: flex;
    margin-bottom: variables.$spacer-1;
}

.article-hero-share a {
    color: variables.$black;
    margin-left: 25px;
}

.article-content .image {
    margin: variables.$spacer-1 0;
}

@include breakpoints.media-breakpoint-up(md) {
    .article-back {
        margin-bottom: variables.$spacer-1;
    }

    .article-hero {
        margin-bottom: variables.$spacer-2;
    }

    .article-hero {
        display: grid;
        column-gap: 25px;
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }

    .article-hero-content {
        grid-column: auto / span 5;
    }

    .article-hero-image {
        grid-column: auto / span 4;
    }

    .article-hero-title {
        font-weight: variables.$font-weight-normal;
        margin-bottom: variables.$spacer-1 * 0.5;
    }

    .article-content .pull-left {
        float: left;
        margin: 25px 25px 25px 0;
        max-width: 40%;
    }

    .article-content .pull-right {
        float: right;
        margin: 25px 0 25px 25px;
        max-width: 40%;
    }
}

@include breakpoints.media-breakpoint-up(lg) {
    .article-hero {
        column-gap: 50px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .article-hero-content {
        grid-column: auto / span 5;
    }

    .article-hero-image {
        grid-column: auto / span 7;
    }

    .article-hero-intro {
        font-size: 2.25rem;
        margin-bottom: variables.$spacer-2;
    }

    .article-content {
        margin: 0 auto;
        padding: 0 120px;
    }

    .article-content .pull-left {
        margin: 50px 50px 50px -120px;
        max-width: 45%;
    }

    .article-content .pull-right {
        margin: 50px -120px 50px 50px;
        max-width: 45%;
    }
}

@include breakpoints.media-breakpoint-up(xl) {
    .article-hero {
        column-gap: 90px;
    }

    .article-hero-intro {
        margin-bottom: variables.$spacer-3;
    }

    .article-content {
        padding: 0 140px;
    }

    .article-content .pull-left {
        margin-left: -140px;
        max-width: 50%;
    }

    .article-content .pull-right {
        margin-right: -140px;
        max-width: 50%;
    }
}
