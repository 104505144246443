@use "../global/variables";
@use "../mixins/breakpoints";

.breadcrumbs {
  display: none;
  font-size: 1.25rem;
  @include breakpoints.media-breakpoint-up(lg) {
    display: block;
  }
}

.breadcrumbs a {
  color: variables.$body-color;
}