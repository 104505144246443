@font-face {
  font-family: "Gotham-A";
  src: url("/assets/fonts/gotham_a_400_normal.eot");
  src: url("/assets/fonts/gotham_a_400_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_400_normal.woff2") format("woff2"), url("/assets/fonts/gotham_a_400_normal.woff") format("woff"), url("/assets/fonts/gotham_a_400_normal.ttf") format("truetype"), url("/assets/fonts/gotham_a_400_normal.svg#gotham_a") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-A";
  src: url("/assets/fonts/gotham_a_400_italic.eot");
  src: url("/assets/fonts/gotham_a_400_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_400_italic.woff2") format("woff2"), url("/assets/fonts/gotham_a_400_italic.woff") format("woff"), url("/assets/fonts/gotham_a_400_italic.ttf") format("truetype"), url("/assets/fonts/gotham_a_400_italic.svg#gotham_a") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-A";
  src: url("/assets/fonts/gotham_a_500_normal.eot");
  src: url("/assets/fonts/gotham_a_500_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_500_normal.woff2") format("woff2"), url("/assets/fonts/gotham_a_500_normal.woff") format("woff"), url("/assets/fonts/gotham_a_500_normal.ttf") format("truetype"), url("/assets/fonts/gotham_a_500_normal.svg#gotham_a") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-A";
  src: url("/assets/fonts/gotham_a_500_italic.eot");
  src: url("/assets/fonts/gotham_a_500_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_500_italic.woff2") format("woff2"), url("/assets/fonts/gotham_a_500_italic.woff") format("woff"), url("/assets/fonts/gotham_a_500_italic.ttf") format("truetype"), url("/assets/fonts/gotham_a_500_italic.svg#gotham_a") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-A";
  src: url("/assets/fonts/gotham_a_700_normal.eot");
  src: url("/assets/fonts/gotham_a_700_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_700_normal.woff2") format("woff2"), url("/assets/fonts/gotham_a_700_normal.woff") format("woff"), url("/assets/fonts/gotham_a_700_normal.ttf") format("truetype"), url("/assets/fonts/gotham_a_700_normal.svg#gotham_a") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-A";
  src: url("/assets/fonts/gotham_a_700_italic.eot");
  src: url("/assets/fonts/gotham_a_700_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_700_italic.woff2") format("woff2"), url("/assets/fonts/gotham_a_700_italic.woff") format("woff"), url("/assets/fonts/gotham_a_700_italic.ttf") format("truetype"), url("/assets/fonts/gotham_a_700_italic.svg#gotham_a") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-B";
  src: url("/assets/fonts/gotham_b_400_normal.eot");
  src: url("/assets/fonts/gotham_b_400_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_400_normal.woff2") format("woff2"), url("/assets/fonts/gotham_b_400_normal.woff") format("woff"), url("/assets/fonts/gotham_b_400_normal.ttf") format("truetype"), url("/assets/fonts/gotham_b_400_normal.svg#gotham_b") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-B";
  src: url("/assets/fonts/gotham_b_400_italic.eot");
  src: url("/assets/fonts/gotham_b_400_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_400_italic.woff2") format("woff2"), url("/assets/fonts/gotham_b_400_italic.woff") format("woff"), url("/assets/fonts/gotham_b_400_italic.ttf") format("truetype"), url("/assets/fonts/gotham_b_400_italic.svg#gotham_b") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-B";
  src: url("/assets/fonts/gotham_b_500_normal.eot");
  src: url("/assets/fonts/gotham_b_500_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_500_normal.woff2") format("woff2"), url("/assets/fonts/gotham_b_500_normal.woff") format("woff"), url("/assets/fonts/gotham_b_500_normal.ttf") format("truetype"), url("/assets/fonts/gotham_b_500_normal.svg#gotham_b") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-B";
  src: url("/assets/fonts/gotham_b_500_italic.eot");
  src: url("/assets/fonts/gotham_b_500_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_500_italic.woff2") format("woff2"), url("/assets/fonts/gotham_b_500_italic.woff") format("woff"), url("/assets/fonts/gotham_b_500_italic.ttf") format("truetype"), url("/assets/fonts/gotham_b_500_italic.svg#gotham_b") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-B";
  src: url("/assets/fonts/gotham_b_700_normal.eot");
  src: url("/assets/fonts/gotham_b_700_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_700_normal.woff2") format("woff2"), url("/assets/fonts/gotham_b_700_normal.woff") format("woff"), url("/assets/fonts/gotham_b_700_normal.ttf") format("truetype"), url("/assets/fonts/gotham_b_700_normal.svg#gotham_b") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham-B";
  src: url("/assets/fonts/gotham_b_700_italic.eot");
  src: url("/assets/fonts/gotham_b_700_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_700_italic.woff2") format("woff2"), url("/assets/fonts/gotham_b_700_italic.woff") format("woff"), url("/assets/fonts/gotham_b_700_italic.ttf") format("truetype"), url("/assets/fonts/gotham_b_700_italic.svg#gotham_b") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  background-color: #fff;
  color: #53555c;
  font-family: Gotham-A, Gotham-B, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  margin: 0;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  color: #ffcd07;
  text-decoration: none;
  transition: color 175ms ease-in;
}
a:hover {
  color: #e8bb06;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #707070;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 0;
}

legend {
  color: inherit;
  display: block;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  padding: 0;
  white-space: normal;
  width: 100%;
  max-width: 100%;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

body {
  font-size: 1rem;
  font-family: Gotham-A, Gotham-B, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.375;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin-bottom: 36px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 68px;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  body {
    font-size: 1.25rem;
    line-height: 1.35;
  }
  h1 {
    font-size: 3.75rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.375rem;
  }
  h6 {
    font-size: 1.125rem;
  }
}
hr {
  border: 0;
  border-top: 1px solid #d7d5d5;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small {
  font-size: 80%;
  font-weight: 400;
}

figcaption {
  font-size: 14px;
  margin-top: 10px;
}

@media (min-width: 1200px) {
  figcaption {
    font-size: 20px;
    margin-top: 25px;
  }
}
.container {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
@media (min-width: 1200px) {
  .container {
    padding-left: 85px;
    padding-right: 85px;
  }
}
@media (min-width: 1654px) {
  .container {
    padding-left: calc(50vw - 742px);
    padding-right: calc(50vw - 742px);
  }
}

.form-group {
  margin-bottom: 20px;
}

.form-group.has-errors .form-control {
  border-color: #dc3545;
}

.form-label {
  display: block;
  font-size: 0.9375rem;
  margin-bottom: 8px;
}

.form-button {
  margin-top: 68px;
}

.form-button .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .form-button .btn {
    border-radius: 25px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: auto;
    min-width: 275px;
  }
}

.form-error {
  color: #dc3545;
  display: block;
  font-size: 0.9375rem;
  margin-top: 8px;
}

.form-error {
  color: #dc3545;
  display: block;
  font-size: 0.9375rem;
  margin-top: 8px;
}

ul.form-error {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.form-error li {
  margin-top: 8px;
}

.form-control {
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid #898989;
  color: #53555c;
  display: block;
  font-size: 0.9375rem;
  height: 50px;
  line-height: 20px;
  padding: 14px 18px;
  transition-duration: 175ms;
  transition-property: box-shadow, border-color;
  transition-timing-function: ease-in;
  width: 100%;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  background-color: #fff;
  border-color: #ffcd07;
  box-shadow: 0 0 0 4px rgba(255, 205, 7, 0.25);
  color: #53555c;
  outline: 0;
}
.form-control::placeholder {
  color: #b9b9b9;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

select.form-control {
  background: #fefbf0 url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M16.001,24.801c-.411,0-.821-.157-1.135-.47L.47,9.939c-.627-.626-.627-1.643,0-2.27s1.643-.627,2.27,0l13.261,13.257L29.26,7.669c.627-.627,1.643-.627,2.27,0s.627,1.643,0,2.27l-14.394,14.392c-.314,.314-.724,.47-1.135,.47Z%22%20fill=%22%23ffcd07%22%20/%3E%3C/svg%3E") no-repeat center right 20px/20px;
  border-color: #ffcd07;
  padding-right: 47px;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #53555c;
}

select.form-control[multiple],
select.form-control[size]:not([size="1"]) {
  height: auto;
}

textarea.form-control {
  height: auto;
  min-height: 160px;
}

.form-checkbox-group {
  display: grid;
}

.form-checkbox {
  background: #f8f8f9;
  display: flex;
  align-items: center;
  padding: 1px;
}

.form-checkbox-input {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: -1;
}

.form-checkbox-option {
  align-items: center;
  background: #fff;
  color: #707070;
  display: flex;
  flex-grow: 1;
  height: 80px;
  padding: 16px 24px;
  transition: background-color 175ms ease-in;
}

.form-checkbox-option:hover {
  background: rgba(255, 255, 255, 0.5);
}

.form-checkbox-indicator {
  height: 28px;
  width: 28px;
  appearance: none;
  background: #fff;
  border: 1px solid #ffcd07;
  border-radius: 14px;
  flex: 0 0 28px;
}

.form-checkbox-label {
  font-size: 0.9375rem;
  margin-left: 38px;
}

.form-checkbox-input:checked ~ .form-checkbox-option {
  background: rgba(255, 255, 255, 0);
}
.form-checkbox-input:checked ~ .form-checkbox-option .form-checkbox-indicator {
  background: #ffcd07 url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M31.072,2.561c-1.076-.879-2.644-.706-3.506,.38L11.239,23.484,4.41,15.177c-.884-1.072-2.452-1.215-3.514-.323-1.057,.896-1.2,2.489-.319,3.563l8.775,10.675c.053,.064,.129,.087,.186,.144,.063,.064,.096,.146,.167,.204,.108,.088,.234,.13,.35,.197,.095,.054,.18,.117,.28,.158,.302,.125,.615,.206,.933,.206,0,0,0,0,.001,0s0,0,.001,0c.325,0,.645-.084,.953-.214,.099-.042,.181-.104,.275-.159,.123-.071,.255-.117,.368-.212,.073-.062,.107-.149,.171-.217,.053-.055,.126-.077,.174-.139L31.447,6.117c.864-1.089,.696-2.679-.375-3.556Z%22%20fill=%22%2353555c%22%20/%3E%3C/svg%3E") no-repeat center center/13px;
}

@media (min-width: 1200px) {
  .form-checkbox-group {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group,
  .form-horizontal .form-button {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    column-gap: 25px;
  }
  .form-horizontal .form-label {
    grid-column: auto/span 2;
    margin: 15px 0 0;
  }
  .form-horizontal .form-group > :nth-child(2) {
    grid-column: auto/span 6;
  }
  .form-horizontal .form-button .btn {
    grid-column-start: 3;
  }
}
@media (min-width: 1200px) {
  .form-horizontal .form-group,
  .form-horizontal .form-button {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 16px;
  }
  .form-horizontal .form-label {
    grid-column: auto/span 3;
  }
  .form-horizontal .form-group > :nth-child(2) {
    grid-column: auto/span 7;
  }
  .form-horizontal .form-button .btn {
    grid-column-start: 4;
  }
}
@media (min-width: 1980px) {
  .form-horizontal .form-group,
  .form-horizontal .form-button {
    column-gap: 50px;
  }
  .form-horizontal .form-label {
    grid-column: 3/span 2;
  }
  .form-horizontal .form-group > :nth-child(2) {
    grid-column: auto/span 5;
  }
  .form-horizontal .form-button .btn {
    grid-column-start: 5;
  }
}
.breadcrumbs {
  display: none;
  font-size: 1.25rem;
}
@media (min-width: 1200px) {
  .breadcrumbs {
    display: block;
  }
}

.breadcrumbs a {
  color: #53555c;
}

.embed-responsive, .video {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.embed-responsive::before, .video::before {
  content: "";
  display: block;
}
.embed-responsive embed, .video embed,
.embed-responsive iframe,
.video iframe,
.embed-responsive object,
.video object,
.embed-responsive video,
.video video,
.embed-responsive .embed-responsive-item,
.video .embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 392.98px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 393px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1979.98px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1980px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.rounded {
  border-radius: 5px;
}
@media (min-width: 1200px) {
  .rounded {
    border-radius: 10px;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

html,
body {
  min-height: 100vh;
  overflow-x: hidden;
}

html {
  width: 100vw;
  min-width: 320px;
  max-width: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

strong, b {
  font-weight: 700;
}

svg {
  fill: currentColor;
}

img {
  height: auto !important;
  max-width: 100%;
}

table {
  width: 100%;
}

label {
  margin-bottom: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: normal;
}

input[type=file] {
  line-height: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#svg-definitions {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

#headlessui-portal-root {
  position: relative;
  z-index: 1000;
}

.accordion-panel {
  border-bottom: 2px solid #e6e5e5;
}

.accordion-panel-header {
  cursor: pointer;
  outline: 0;
  padding: 15px 28px 15px 0;
  position: relative;
}

.accordion-panel-header::after {
  position: absolute;
  top: 11px;
  right: 0;
  height: 28px;
  width: 28px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M16.001,24.801c-.411,0-.821-.157-1.135-.47L.47,9.939c-.627-.626-.627-1.643,0-2.27s1.643-.627,2.27,0l13.261,13.257L29.26,7.669c.627-.627,1.643-.627,2.27,0s.627,1.643,0,2.27l-14.394,14.392c-.314,.314-.724,.47-1.135,.47Z%22%20fill=%22%23ffcd07%22%20/%3E%3C/svg%3E") no-repeat center center/28px;
  content: "";
  display: block;
  transition: transform 350ms ease-in-out;
}

.accordion-panel.active .accordion-panel-header::after {
  transform: rotate(180deg);
}

.accordion-panel-title {
  font-size: inherit;
  line-height: inherit;
  font-weight: 500;
  margin-bottom: 0;
}

.accordion-panel-body {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 350ms ease-in-out;
}

.accordion-panel.active .accordion-panel-body {
  height: auto;
}

.accordion-panel-body-inner {
  padding: 0 0 25px;
}

.accordion-panel-content > :last-child {
  margin-bottom: 0;
}

.accordion-panel-image {
  margin-top: 25px;
}

.accordion-panel-image img,
.accordion-panel-image video {
  height: auto !important;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .accordion-panel-header {
    padding: 25px 0;
  }
  .accordion-panel-header::after {
    top: 23px;
  }
  .accordion-panel-body-inner {
    padding-bottom: 36px;
  }
}
.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 25px;
  color: #53555c;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
  transition-duration: 175ms;
  transition-property: background, border-color, color;
  transition-timing-function: ease-in;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.btn:hover {
  color: #53555c;
  text-decoration: none;
}
.btn:focus {
  outline: 0;
}
.btn:disabled {
  opacity: 0.6;
  cursor: default;
}
@media (min-width: 1200px) {
  .btn {
    border-radius: 30px;
    font-size: 1.125rem;
    padding: 19px 36px;
  }
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  background-color: #ffcd07;
  border-color: #ffcd07;
  color: #000;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #e8bb06;
  border-color: #e8bb06;
  color: #000;
}

.download-grid {
  display: grid;
}

.download {
  align-items: center;
  border-bottom: 2px solid #e6e5e5;
  display: flex;
  padding: 18px 0;
}

.download-view,
.download-download {
  color: #ffcd07;
  transition: color 175ms ease-in;
}

.download-view:hover,
.download-download:hover {
  color: #53555c;
}
.page-section-dark .download-view:hover,
.page-section-dark .download-download:hover {
  color: #e6e5e5;
}

.download-download {
  margin-left: 20px;
}

.download-title {
  margin-left: 40px;
}

@media (max-width: 767.98px) {
  .download-download {
    display: none;
  }
}
@media (min-width: 768px) {
  .download-grid {
    column-gap: 25px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 36px;
  }
}
@media (min-width: 1200px) {
  .download-grid {
    column-gap: 50px;
  }
}
.gallery {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
}

.gallery-track {
  display: flex;
  align-items: center;
}

.gallery-image-wrapper {
  position: relative;
  padding-bottom: 90%;
  width: 100%;
}

.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat center center/contain;
  padding-bottom: 100%;
  width: 100%;
}

.gallery-thumbs {
  display: none;
  column-gap: 36px;
  justify-content: center;
}
@media (min-width: 768px) {
  .gallery-thumbs {
    display: flex;
  }
}

.gallery-thumb {
  height: 94px;
  width: 94px;
  background: no-repeat center center/cover;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 175ms ease-in;
}
.gallery-thumb.is-hover {
  border-color: #2f3036;
}
.gallery-thumb.is-active {
  border-color: #ffcd07;
}

.gallery-leave-active {
  opacity: 1;
  transition: opacity 275ms ease-in;
}

.gallery-enter-active {
  opacity: 0;
  transition: opacity 275ms 275ms ease-in;
}

.gallery-enter-to {
  opacity: 1;
}

.gallery-leave-to {
  opacity: 0;
}

.modal {
  position: relative;
  z-index: 50;
}

.modal-shade,
.modal-scroller {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-shade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-scroller {
  overflow-y: auto;
}

.modal-inner {
  align-items: center;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: 25px 20px;
}

.modal-panel {
  position: relative;
  background: #fff;
  padding: 20px 15px;
  width: 100%;
  max-width: 1080px;
}

.modal-close,
.modal-prev,
.modal-next {
  height: 55px;
  width: 55px;
  align-items: center;
  border: 0;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: 175ms ease-in;
  transition-property: background-color, color;
}
.modal-close:focus,
.modal-prev:focus,
.modal-next:focus {
  outline: 0;
}

.modal-close {
  position: absolute;
  top: 36px;
  right: 36px;
  background: rgba(230, 229, 229, 0);
  color: #ffcd07;
}
.modal-close:hover {
  background: rgba(230, 229, 229, 0.35);
  color: #53555c;
}

.modal-close svg {
  height: 25px;
  width: 25px;
}

.modal-prev,
.modal-next {
  position: absolute;
  top: 50%;
  background: rgba(230, 229, 229, 0.35);
  margin-top: -28px;
}
.modal-prev:hover,
.modal-next:hover {
  background: #e6e5e5;
}

.modal-prev {
  left: 36px;
}

.modal-next {
  right: 36px;
}

.modal-prev svg,
.modal-next svg {
  height: 18px;
  width: 18px;
  display: block;
}

.modal-prev svg {
  margin-left: -4px;
}

.modal-next svg {
  margin-right: -4px;
}

@media (min-width: 768px) {
  .modal-panel {
    padding: 127px 36px 36px;
  }
  .modal-title {
    margin-bottom: 36px;
  }
}
@media (min-width: 1200px) {
  .modal-panel {
    padding: 127px 68px 68px;
  }
}
.modal-enter-active,
.modal-shade-enter-active {
  transition: 375ms ease-out;
}

.modal-leave-active,
.modal-shade-leave-active {
  transition: 275ms ease-in;
}

.modal-enter-from,
.modal-leave-to,
.modal-shade-enter-from,
.modal-shade-leave-to {
  opacity: 0;
}

.modal-enter-from {
  transform: translate(0, 30px);
}

.modal-leave-to {
  transform: translate(0, -30px);
}

.modal-enter-to,
.modal-leave-from,
.modal-shade-enter-to,
.modal-shade-leave-from {
  opacity: 1;
}

.page-title {
  text-align: center;
}

.page-intro {
  margin: 0 auto 68px;
  text-align: center;
  max-width: 570px;
}

.page-section-dark,
.page-section-grey,
.page-section-light {
  padding-top: 68px;
  padding-bottom: 68px;
}

.page-section-visualiser {
  padding-bottom: 68px;
}

.page-section-dark + .page-section-dark,
.page-section-grey + .page-section-grey,
.page-section-light + .page-section-light {
  padding-top: 0;
}

.page-section-dark {
  background: #53555c;
  color: #fff;
}

.page-section-grey {
  background: #f8f8f9;
}

.page-button {
  margin-top: 68px;
  text-align: center;
}

.page-button.left {
  text-align: left;
}

.page-button .btn {
  min-width: 290px;
}

@media (min-width: 768px) {
  .page-intro {
    margin-bottom: 104px;
  }
}
@media (min-width: 1200px) {
  .page-intro {
    max-width: 800px;
    margin-bottom: 136px;
  }
  .page-section-dark,
  .page-section-grey,
  .page-section-light {
    padding-top: 136px;
    padding-bottom: 136px;
  }
  .page-section-dark__heading, .page-section-grey__heading, .page-button__heading {
    padding-top: 68px;
  }
  .page-section-dark__heading .page-title, .page-section-grey__heading .page-title, .page-button__heading .page-title {
    margin-bottom: 68px;
  }
  .page-button {
    margin-top: 104px;
  }
  .page-button .btn {
    min-width: 350px;
  }
}
.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 104px;
}

.pagination-item + .pagination-item {
  margin-left: 25px;
}

.pagination-item svg {
  display: block;
}

.pagination-link,
.pagination-link:hover {
  color: #53555c;
  text-decoration: none;
}

.pagination-link.is-active {
  font-weight: 500;
}

@media (min-width: 1200px) {
  .pagination {
    margin-top: 136px;
  }
  .pagination-item + .pagination-item {
    margin-left: 50px;
  }
}
.site-footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #53555c;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding-top: 68px;
}

.site-footer-title {
  color: #fff;
  font-weight: 500;
}

.site-footer-link {
  color: #fff;
}
.site-footer-link:hover, .site-footer-link:focus {
  color: #fff;
}

.site-footer-logo {
  display: none;
  width: 250px;
}

.site-footer-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.site-footer-content-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.site-footer-content-nav {
  margin-top: 68px;
  text-align: center;
  width: 100%;
}

.site-footer-content-nav-left,
.site-footer-content-nav-centre,
.site-footer-content-nav-right {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.site-footer-content-nav-centre,
.site-footer-content-nav-right {
  margin-top: 24px;
}

.site-footer-content-btn {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 290px;
}

.site-footer-content-social {
  margin: 68px auto 0;
  width: 30px;
}

.site-footer-australian {
  margin-top: 68px;
  text-align: center;
}

.site-footer-australian svg {
  width: 72px;
}

.site-footer-copyright {
  color: #e6e5e5;
  border-top: 1px solid #d7d5d5;
  margin-top: 36px;
  padding-top: 36px;
  padding-bottom: 68px;
  text-align: center;
}

@media (min-width: 768px) {
  .site-footer-content-nav {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
  }
  .site-footer-content-nav-left,
  .site-footer-content-nav-centre,
  .site-footer-content-nav-right {
    align-items: flex-start;
    row-gap: 10px;
  }
  .site-footer-content-nav-centre,
  .site-footer-content-nav-right {
    margin-top: 0;
  }
  .site-footer-content-social {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    width: 100%;
  }
  .site-footer-content-social a {
    height: 32px;
    width: 32px;
    display: block;
    grid-column-start: 3;
  }
  .site-footer-australian {
    text-align: left;
  }
  .site-footer-copyright {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .site-footer-logo {
    display: block;
  }
  .site-footer-content {
    align-items: flex-start;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 68px;
  }
  .site-footer-content-btn {
    font-size: 15px;
    grid-column: auto/span 4;
    margin-left: auto;
    padding-top: 9px;
    padding-bottom: 9px;
    width: 190px;
    order: 2;
  }
  .site-footer-content-inner {
    grid-column: auto/span 8;
    grid-template-columns: repeat(5, 1fr);
    order: 1;
  }
  .site-footer-content-nav {
    margin-top: 0;
  }
  .site-footer-australian {
    text-align: right;
  }
  .site-footer-australian svg {
    width: 92px;
  }
}
.site-header {
  position: fixed;
  top: 0;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  color: #2f3036;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  transition-duration: 125ms;
  transition-property: background, color, padding;
  transition-timing-function: ease-in;
  z-index: 10;
}
.header-dark .site-header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.header-collapse .site-header {
  padding-top: 7px;
  padding-bottom: 8px;
}

.site-header-push {
  height: 110px;
}

.site-header-logo,
.site-header-logo svg {
  height: 28px;
  width: 156px;
}

.site-header-logo {
  position: relative;
  display: block;
}

.site-header-logo svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 125ms ease-in;
}

.site-header-logo-dark {
  opacity: 1;
}
.header-dark .site-header-logo-dark {
  opacity: 0;
}

.site-header-logo-light {
  opacity: 0;
}
.header-dark .site-header-logo-light {
  opacity: 1;
}

.site-header-menu-toggle {
  position: relative;
  height: 28px;
  width: 28px;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
}
.site-header-menu-toggle:focus {
  outline: none;
  box-shadow: none;
}

.site-header-btn {
  display: none;
  font-size: 15px;
  margin-right: 36px;
  padding: 6px 12px;
  line-height: 20px;
  width: 160px;
}

@media (max-width: 1199.98px) {
  .site-header-push {
    height: 50px;
  }
  .site-header,
  .header-dark .site-header,
  .header-collapse .site-header {
    backdrop-filter: none;
    background: none;
    padding: 0;
  }
  .site-header-mobile {
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;
    transition: background 275ms ease-in;
    transition-delay: 620.75ms;
    z-index: 10;
  }
  .menu-active .site-header-mobile {
    background: #53555c;
    transition-delay: 0ms;
  }
  .site-header-logo {
    margin-right: auto;
  }
  .site-header-logo-dark {
    opacity: 0;
  }
  .site-header-logo-light {
    opacity: 1;
  }
  .site-header-menu-toggle-bar {
    height: 2px;
    width: 28px;
    background: #fff;
    border-radius: 1px;
    display: block;
    transition-property: background, transform, opacity;
    transition-duration: 375ms;
    transition-timing-function: ease-out;
  }
  .site-header-menu-toggle-bar.open-1 {
    position: absolute;
    top: 2px;
    transition-delay: 770.75ms;
  }
  .site-header-menu-toggle-bar.open-2 {
    position: absolute;
    top: 13px;
    transition-delay: 620.75ms;
  }
  .site-header-menu-toggle-bar.open-3 {
    position: absolute;
    top: 24px;
    transition-delay: 920.75ms;
  }
  .menu-active .site-header-menu-toggle-bar.open-1, .menu-active .site-header-menu-toggle-bar.open-2, .menu-active .site-header-menu-toggle-bar.open-3 {
    transform: translate(20px, 0);
    opacity: 0;
  }
  .menu-active .site-header-menu-toggle-bar.open-1 {
    transition-delay: 150ms;
  }
  .menu-active .site-header-menu-toggle-bar.open-2 {
    transition-delay: 0ms;
  }
  .menu-active .site-header-menu-toggle-bar.open-3 {
    transition-delay: 300ms;
  }
  .site-header-menu-toggle-bar.close-1 {
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 0;
    transform: rotate(45deg) translate(-20px, 0);
    transform-origin: left;
    transition-delay: 150ms;
  }
  .site-header-menu-toggle-bar.close-2 {
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0;
    transform: rotate(-45deg) translate(20px, 0);
    transform-origin: right;
    transition-delay: 0ms;
  }
  .menu-active .site-header-menu-toggle-bar.close-1, .menu-active .site-header-menu-toggle-bar.close-2 {
    opacity: 1;
  }
  .menu-active .site-header-menu-toggle-bar.close-1 {
    transform: rotate(45deg);
    transition-delay: 525ms;
  }
  .menu-active .site-header-menu-toggle-bar.close-2 {
    transform: rotate(-45deg);
    transition-delay: 375ms;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .site-header-push {
    height: 68px;
  }
  .site-header-mobile {
    padding-bottom: 12px;
  }
  .site-header-btn {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .site-header-logo,
  .site-header-logo svg {
    height: 45px;
    width: 250px;
  }
}
@media (min-width: 1200px) {
  .site-header-mobile {
    padding: 0;
    width: auto;
    margin-right: auto;
  }
  .site-header-menu-toggle {
    display: none;
  }
}
.site-main {
  flex-grow: 1;
}

@media (max-width: 1199.98px) {
  html.menu-active {
    overflow: hidden;
  }
  .site-menu {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 136px 68px;
    transition: visibility 0ms linear;
    transition-delay: 1370.75ms;
    visibility: hidden;
    z-index: 5;
  }
  .menu-active .site-menu {
    visibility: visible;
    transition-delay: 0ms;
  }
  .site-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .site-menu-item {
    opacity: 0;
    transform: translate(40px, 0);
    transition-duration: 275ms;
    transition-property: opacity, transform;
    transition-timing-function: ease-in;
  }
  .menu-active .site-menu-item {
    opacity: 1;
    transform: translate(0, 0);
  }
  .site-menu-item + .site-menu-item {
    margin-top: 5px;
  }
  .site-menu-link {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
  }
  .site-menu-link:hover, .site-menu-link:focus {
    color: #fff;
    text-decoration: none;
  }
  .site-menu-item:nth-child(1) {
    transition-delay: 0ms;
  }
  .menu-active .site-menu-item:nth-child(1) {
    transition-delay: 250ms;
  }
  .site-menu-item:nth-child(2) {
    transition-delay: 85ms;
  }
  .menu-active .site-menu-item:nth-child(2) {
    transition-delay: 335ms;
  }
  .site-menu-item:nth-child(3) {
    transition-delay: 170ms;
  }
  .menu-active .site-menu-item:nth-child(3) {
    transition-delay: 420ms;
  }
  .site-menu-item:nth-child(4) {
    transition-delay: 255ms;
  }
  .menu-active .site-menu-item:nth-child(4) {
    transition-delay: 505ms;
  }
  .site-menu-item:nth-child(5) {
    transition-delay: 340ms;
  }
  .menu-active .site-menu-item:nth-child(5) {
    transition-delay: 590ms;
  }
  .site-menu-item:nth-child(6) {
    transition-delay: 425ms;
  }
  .menu-active .site-menu-item:nth-child(6) {
    transition-delay: 675ms;
  }
  .site-menu-item:nth-child(7) {
    transition-delay: 510ms;
  }
  .menu-active .site-menu-item:nth-child(7) {
    transition-delay: 760ms;
  }
  .site-menu-item:nth-child(8) {
    transition-delay: 595ms;
  }
  .menu-active .site-menu-item:nth-child(8) {
    transition-delay: 845ms;
  }
  .site-menu-item-btn {
    display: none;
    margin-top: auto;
    opacity: 0;
    transform: translate(0, 20px);
    transition-delay: 375ms;
    transition-duration: 275ms;
    transition-property: opacity, transform;
    transition-timing-function: ease-in;
  }
  .menu-active .site-menu-item-btn {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: 375ms;
  }
  .site-menu-mobile-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    backdrop-filter: blur(3px);
    background: rgba(83, 85, 92, 0.85);
    height: calc(max(100vw, 100vh) * 1.414214);
    transform: translate(-50%, -50%) rotate(-45deg) scaleY(0);
    transition-delay: 620.75ms;
    transition-duration: 750ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;
    width: calc(max(100vw, 100vh) * 1.414214);
  }
  .menu-active .site-menu-mobile-bg {
    transform: translate(-50%, -50%) rotate(-45deg) scaleY(1);
    transition-delay: 0ms;
  }
}
@media (max-width: 767.98px) {
  .site-menu-item-btn {
    display: block;
  }
  .site-menu-item-copy {
    margin-top: 36px;
  }
}
@media (min-width: 1200px) {
  .site-menu {
    align-items: center;
    display: flex;
  }
  .site-menu-container {
    position: relative;
    align-items: center;
    display: flex;
    margin-right: 15px;
  }
  .site-menu-item {
    padding: 0 15px;
  }
  .site-menu-item.is-active {
    font-weight: 500;
  }
  .site-menu-link {
    font-size: 15px;
    line-height: 20px;
    white-space: nowrap;
  }
  .site-menu-link,
  .site-menu-link:hover,
  .site-menu-link:focus {
    color: inherit;
    text-decoration: none;
  }
  .site-menu-item-btn {
    font-size: 15px;
    line-height: 20px;
    padding: 9px 12px;
    width: 160px;
  }
  .site-menu-mobile-bg,
  .site-menu-item-copy {
    display: none;
  }
  .site-menu-underline {
    position: absolute;
    top: 100%;
    height: 2px;
    width: 200px;
    background: currentColor;
    border-radius: 1px;
    transition-duration: 175ms;
    transition-property: left, width;
    transition-timing-function: ease-in;
  }
}
.skipnav {
  text-align: left;
}

.skipnav a {
  position: absolute;
  left: -9999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  display: inline-block;
}

.skipnav a:focus,
.skipnav a:active {
  left: 0;
  height: auto;
  width: auto;
  background: #fff;
  overflow: visible;
  padding: 5px;
  text-decoration: underline;
  z-index: 10000;
}

.video {
  background: no-repeat center center/cover;
  cursor: pointer;
  padding-bottom: 56.25%;
}

.video::before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M10.831,24.956c-.292,0-.53-.237-.53-.529h0V7.575c0-.293,.236-.531,.528-.531,.094,0,.186,.024,.268,.072l14.594,8.426c.253,.146,.34,.47,.194,.724-.046,.08-.113,.147-.194,.194l-14.594,8.426c-.081,.046-.172,.071-.265,.071m.53-16.463v15.016l13.005-7.508-13.005-7.508Z%22%20fill=%22%23fff%22/%3E%3Cpath%20d=%22M16,32C7.163,32,0,24.837,0,16S7.163,0,16,0s16,7.163,16,16c-.01,8.832-7.168,15.99-16,16M16,1.06C7.749,1.06,1.06,7.749,1.06,16s6.689,14.94,14.94,14.94,14.94-6.689,14.94-14.94c-.008-8.248-6.693-14.932-14.94-14.94%22%20fill=%22%23fff%22%20/%3E%3C/svg%3E") no-repeat center center/contain;
  content: "";
  display: block;
  transform: translate(-50%, -50%);
}

.video.active::before {
  display: none;
}

@media (min-width: 768px) {
  .video::before {
    height: 110px;
    width: 110px;
  }
}
.video-grid {
  display: grid;
  column-gap: 25px;
  row-gap: 36px;
  grid-template-columns: minmax(0, 1fr);
}

.video-item-thumb {
  background: no-repeat center center/cover;
  display: block;
  padding-bottom: 56.25%;
}

.video-player {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}

.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .video-grid {
    column-gap: 50px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 68px;
  }
}
@media (min-width: 1980px) {
  .video-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.article-back {
  margin-bottom: 18px;
}

.article-hero {
  margin-bottom: 36px;
}

.article-hero-title {
  line-height: 1.2;
  margin-bottom: 36px;
}

.article-hero-intro {
  font-size: 1.375rem;
  line-height: 1.25;
  margin-bottom: 27px;
}

.article-hero-intro > :last-child {
  margin-bottom: 0;
}

.article-hero-share {
  align-items: center;
  display: flex;
  margin-bottom: 36px;
}

.article-hero-share a {
  color: #000;
  margin-left: 25px;
}

.article-content .image {
  margin: 36px 0;
}

@media (min-width: 768px) {
  .article-back {
    margin-bottom: 36px;
  }
  .article-hero {
    margin-bottom: 68px;
  }
  .article-hero {
    display: grid;
    column-gap: 25px;
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .article-hero-content {
    grid-column: auto/span 5;
  }
  .article-hero-image {
    grid-column: auto/span 4;
  }
  .article-hero-title {
    font-weight: 400;
    margin-bottom: 18px;
  }
  .article-content .pull-left {
    float: left;
    margin: 25px 25px 25px 0;
    max-width: 40%;
  }
  .article-content .pull-right {
    float: right;
    margin: 25px 0 25px 25px;
    max-width: 40%;
  }
}
@media (min-width: 1200px) {
  .article-hero {
    column-gap: 50px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .article-hero-content {
    grid-column: auto/span 5;
  }
  .article-hero-image {
    grid-column: auto/span 7;
  }
  .article-hero-intro {
    font-size: 2.25rem;
    margin-bottom: 68px;
  }
  .article-content {
    margin: 0 auto;
    padding: 0 120px;
  }
  .article-content .pull-left {
    margin: 50px 50px 50px -120px;
    max-width: 45%;
  }
  .article-content .pull-right {
    margin: 50px -120px 50px 50px;
    max-width: 45%;
  }
}
@media (min-width: 1980px) {
  .article-hero {
    column-gap: 90px;
  }
  .article-hero-intro {
    margin-bottom: 104px;
  }
  .article-content {
    padding: 0 140px;
  }
  .article-content .pull-left {
    margin-left: -140px;
    max-width: 50%;
  }
  .article-content .pull-right {
    margin-right: -140px;
    max-width: 50%;
  }
}
.contact-checkbox-group {
  background: #f8f8f9;
  padding: 1px;
  gap: 1px;
  display: grid;
}

.contact-checkbox {
  align-items: center;
  background: #fff;
  color: #707070;
  display: flex;
  padding: 32px 24px;
  transition: background-color 175ms ease-in;
}

.contact-checkbox:hover {
  background: rgba(255, 255, 255, 0.5);
}

.contact-checkbox.is-checked {
  background: rgba(255, 255, 255, 0);
}

.contact-checkbox-input {
  height: 28px;
  width: 28px;
  appearance: none;
  background: #fff;
  border: 1px solid #ffcd07;
  border-radius: 14px;
  flex: 0 0 28px;
}

.contact-checkbox-input:checked {
  background: #ffcd07 url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M31.072,2.561c-1.076-.879-2.644-.706-3.506,.38L11.239,23.484,4.41,15.177c-.884-1.072-2.452-1.215-3.514-.323-1.057,.896-1.2,2.489-.319,3.563l8.775,10.675c.053,.064,.129,.087,.186,.144,.063,.064,.096,.146,.167,.204,.108,.088,.234,.13,.35,.197,.095,.054,.18,.117,.28,.158,.302,.125,.615,.206,.933,.206,0,0,0,0,.001,0s0,0,.001,0c.325,0,.645-.084,.953-.214,.099-.042,.181-.104,.275-.159,.123-.071,.255-.117,.368-.212,.073-.062,.107-.149,.171-.217,.053-.055,.126-.077,.174-.139L31.447,6.117c.864-1.089,.696-2.679-.375-3.556Z%22%20fill=%22%2353555c%22%20/%3E%3C/svg%3E") no-repeat center center/13px;
}

.contact-checkbox-label {
  margin-left: 38px;
}

.contact-checkbox-label-title {
  font-size: 0.9375rem;
  font-weight: 500;
}

.contact-checkbox-label-description {
  font-size: 0.8125rem;
}

@media (min-width: 1200px) {
  .contact-checkbox-group {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.deep-content .page-section-grey,
.deep-content .page-section-light {
  overflow: hidden;
}
.deep-content .deep-content-title + .page-section-light {
  margin-top: -68px;
}

.deep-feature-left,
.deep-feature-right {
  align-items: center;
  display: grid;
}

.deep-grid-three > div + div {
  margin-top: 36px;
}

@media (max-width: 767.98px) {
  .deep-feature-left h2,
  .deep-feature-right h2 {
    text-align: center;
  }
  .deep-feature-left img,
  .deep-feature-left .video,
  .deep-feature-right img,
  .deep-feature-right .video {
    margin: 0 auto 36px;
  }
  .deep-feature-left > :nth-child(1),
  .deep-feature-right > :nth-child(1) {
    order: 2;
  }
  .deep-feature-left > :nth-child(2),
  .deep-feature-right > :nth-child(2) {
    order: 1;
  }
}
@media (min-width: 768px) {
  .deep-feature-left,
  .deep-feature-right {
    column-gap: 35px;
    grid-template-columns: repeat(2, 1fr);
  }
  .deep-feature-left > :nth-child(1) {
    order: 2;
  }
  .deep-feature-left > :nth-child(2) {
    order: 1;
  }
  .deep-grid-three > div + div {
    margin-top: 68px;
  }
}
@media (min-width: 1200px) {
  .deep-content .page-section-grey + .page-section-grey,
  .deep-content .page-section-light + .page-section-light {
    margin-top: -136px;
    padding-top: 104px;
  }
  .deep-content .deep-content-title + .page-section-light {
    padding-top: 0;
  }
  .deep-feature-left,
  .deep-feature-right {
    column-gap: 50px;
  }
  .deep-grid-three {
    display: grid;
    column-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
  }
  .deep-grid-three > div + div {
    margin-top: 0;
  }
}
#difference .site-header-push {
  display: none;
}

.difference-banner {
  position: relative;
  background: url("/assets/images/difference-sm.jpg") no-repeat center/cover;
  display: flex;
  height: 635px;
  max-height: 90vh;
}

.difference-banner-content {
  position: relative;
  margin: 85px auto 0;
  text-align: center;
}

.difference-banner-content-title {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 20px;
}

.difference-banner-btn {
  position: absolute;
  top: 100%;
  left: 50%;
  border: 3px solid #fff;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.difference-banner-btn .btn {
  min-width: 290px;
}

.difference-banner-pin {
  position: relative;
  cursor: pointer;
  z-index: 90;
}

.difference-banner-pin-bg {
  fill: rgba(255, 255, 255, 0.17);
  transition: transform 175ms ease-in;
}
.difference-banner-pin:hover .difference-banner-pin-bg {
  transform: scale(1.3);
}

.difference-banner-pin-icon {
  fill: #ffcd07;
}

.difference-banner-pin-tooltip {
  position: absolute;
  background: #ffcd07;
  color: #2f3036;
  font-size: 0.8125rem;
  padding: 5px 15px;
  transform: translate(calc(-100% + 25px), -25px);
  white-space: nowrap;
  z-index: 100;
}

.difference-banner-pin-tooltip::before {
  position: absolute;
  top: 100%;
  right: 0;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #ffcd07;
  border-right-color: #ffcd07;
  content: "";
  display: block;
}

@media (max-width: 767.98px) {
  .difference-banner-bg,
  .difference-banner-btn {
    display: none;
  }
}
@media (min-width: 393px) {
  .difference-banner {
    background-image: url("/assets/images/difference-md.jpg");
  }
}
@media (min-width: 768px) {
  .difference-banner {
    position: relative;
    background: none;
    height: 950px;
  }
  .difference-banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .difference-banner-content {
    position: relative;
    margin-top: 140px;
    pointer-events: none;
    width: 500px;
  }
  .difference-banner-content-title {
    margin-bottom: 36px;
  }
}
@media (min-width: 1200px) {
  .difference-banner {
    height: 820px;
  }
  .difference-banner::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 100%);
    content: "";
    pointer-events: none;
    width: 50%;
    z-index: 1;
  }
  .difference-banner-content {
    margin: auto 0;
    text-align: left;
    width: 290px;
    z-index: 2;
  }
  .difference-banner-content-title {
    font-size: 3.75rem;
  }
  .difference-banner-btn .btn {
    min-width: 350px;
  }
}
@media (min-width: 1980px) {
  .difference-banner {
    height: 980px;
  }
}
.difference-banner-pin-enter-active,
.difference-banner-pin-leave-active {
  transition: opacity 375ms ease-in;
}

.difference-banner-pin-enter-from,
.difference-banner-pin-leave-to {
  opacity: 0;
}

.difference-banner-pin-enter-to,
.difference-banner-pin-leave-from {
  opacity: 1;
}

.difference-modal-panel {
  padding: 95px 115px;
}

.difference-modal-body {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.difference-modal-image {
  margin-top: 36px;
}

.difference-modal-image img,
.difference-modal-image video {
  height: auto !important;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .difference-modal-panel {
    padding: 135px 155px;
  }
  .difference-modal-body {
    align-items: center;
    flex-direction: row;
    min-height: 307px;
    text-align: left;
  }
  .difference-modal-content {
    padding-left: 85px;
    order: 2;
  }
  .difference-modal-content .modal-title {
    line-height: 1.1;
    margin-bottom: 24px;
  }
  .difference-modal-image {
    flex-shrink: 0;
    margin-top: 0;
    order: 1;
    max-width: 315px;
  }
}
.difference-modal-wrapper {
  position: relative;
  transition: height 275ms ease-in;
}

.difference-modal-leave-active {
  position: absolute;
  opacity: 1;
  transition: opacity 275ms ease-in;
}

.difference-modal-enter-active {
  opacity: 0;
  transition: opacity 275ms 275ms ease-in;
}

.difference-modal-enter-to {
  opacity: 1;
}

.difference-modal-leave-to {
  opacity: 0;
}

.difference-features {
  padding-top: 36px;
}

@media (min-width: 768px) {
  .difference-features {
    display: none;
  }
}
.difference-controls-grid {
  display: grid;
  row-gap: 36px;
  margin-top: -36px;
}

.difference-controls-heading {
  margin-top: 36px;
}

.difference-controls-title {
  font-size: 1.875rem;
  margin-bottom: 0;
}

.difference-controls-automate {
  width: 226px;
  margin-top: 15px;
}

.difference-controls-content-text > :last-child {
  margin-bottom: 0;
}

.difference-controls-content-image {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.difference-controls-content-remote {
  width: 45px;
}

.difference-controls-content-smart {
  width: 254px;
}

.difference-controls-content-crank {
  width: 48px;
}

.difference-controls-content-spring {
  width: 86px;
}

@media (min-width: 768px) {
  .difference-controls-grid {
    column-gap: 68px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 0;
    row-gap: 104px;
  }
  .difference-controls-heading {
    margin: 0;
  }
  .difference-controls-content {
    display: flex;
    flex-direction: column;
  }
  .difference-controls-content-image {
    align-items: center;
    justify-content: flex-start;
    height: 124px;
    margin: 0 0 36px;
    order: 1;
  }
  .difference-controls-content-text {
    order: 2;
  }
  .difference-controls-content-remote {
    width: 30px;
  }
  .difference-controls-content-smart {
    width: 182px;
  }
  .difference-controls-content-crank {
    width: 33px;
  }
  .difference-controls-content-spring {
    width: 73px;
  }
}
@media (min-width: 1200px) {
  .difference-controls-grid {
    margin-top: 104px;
  }
  .difference-controls-title {
    font-size: 2.5rem;
  }
  .difference-controls-content-remote {
    width: 40px;
  }
  .difference-controls-content-smart {
    width: 244px;
  }
  .difference-controls-content-crank {
    width: 48px;
  }
  .difference-controls-content-spring {
    width: 88px;
  }
}
.difference-innovation {
  display: grid;
  row-gap: 36px;
}

.difference-innovation-intro .page-title {
  font-size: 2.5rem;
  line-height: 1.15;
  margin-bottom: 36px;
}

.difference-innovation-wind {
  font-size: 0.9375rem;
  padding: 36px 0;
}

.difference-innovation-wind-image {
  display: none;
}

@media (min-width: 768px) {
  .difference-innovation {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 36px;
  }
  .difference-innovation-intro .page-title {
    font-size: 3rem;
    text-align: left;
  }
  .difference-innovation-wind {
    display: flex;
    grid-column: auto/span 2;
    padding-bottom: 0;
  }
  .difference-innovation-wind-image {
    height: 100px;
    width: 100px;
    align-items: center;
    background-color: rgba(230, 229, 229, 0.3);
    border-radius: 50px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 36px;
  }
  .difference-innovation-wind-image svg {
    width: 60px;
  }
  .difference-innovation-video {
    grid-column: auto/span 2;
  }
}
@media (min-width: 1200px) {
  .difference-innovation {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    row-gap: 68px;
    column-gap: 16px;
  }
  .difference-innovation-intro {
    grid-column: 1/span 6;
  }
  .difference-innovation-intro .page-title {
    font-size: 3.75rem;
    margin-bottom: 68px;
  }
  .difference-innovation-image {
    grid-column: 8/span 5;
    grid-row: 1/span 2;
  }
  .difference-innovation-wind {
    align-self: end;
    grid-column: 1/span 6;
    grid-row: 2/span 2;
    padding: 0;
  }
  .difference-innovation-wind-image {
    height: 140px;
    width: 140px;
    border-radius: 70px;
  }
  .difference-innovation-wind-image svg {
    width: 85px;
  }
  .difference-innovation-video {
    grid-column: auto/span 12;
  }
}
.difference-efficiency {
  display: grid;
  row-gap: 36px;
  margin-bottom: 68px;
}

.difference-efficiency-content .page-title {
  font-size: 2.5rem;
  line-height: 1.15;
  margin-bottom: 36px;
}

@media (min-width: 768px) {
  .difference-efficiency {
    column-gap: 25px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .difference-efficiency-content {
    order: 2;
  }
  .difference-efficiency-content .page-title {
    font-size: 3rem;
    text-align: left;
  }
  .difference-efficiency-image {
    order: 1;
  }
}
@media (min-width: 1200px) {
  .difference-efficiency {
    column-gap: 16px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin-bottom: 104px;
  }
  .difference-efficiency-content {
    grid-column: 7/span 6;
  }
  .difference-efficiency-content .page-title {
    font-size: 3.75rem;
    margin-bottom: 68px;
  }
  .difference-efficiency-image {
    grid-column: 1/span 5;
  }
}
.difference-range .page-title {
  font-size: 2.5rem;
  line-height: 1.15;
}

.difference-range-image {
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (min-width: 768px) {
  .difference-range-item-image {
    display: block;
  }
}
@media (min-width: 1200px) {
  .difference-range {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .difference-range .page-title {
    font-size: 3.75rem;
    grid-column: 1/span 3;
    text-align: left;
  }
  .difference-range .page-button {
    grid-column: 1/span 3;
    margin-top: 0;
  }
  .difference-range-image {
    align-self: center;
    grid-column: 6/span 6;
    grid-row: 1/span 2;
  }
}
.gallery-tabs {
  position: relative;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  margin-bottom: 36px;
}

.gallery-tabs-item {
  color: #53555c;
  flex: 0 0 50%;
  padding-bottom: 24px;
  text-align: center;
  width: 100%;
}

.gallery-tabs-underline {
  position: absolute;
  bottom: 0;
  background: #707070;
  height: 7px;
  pointer-events: none;
  transition-duration: 175ms;
  transition-property: left, width;
  transition-timing-function: ease-in;
}

.gallery-tabs-item:hover {
  color: #53555c;
  text-decoration: none;
}

.gallery-grid {
  display: grid;
  gap: 36px;
}

.gallery-grid-item {
  position: relative;
  padding-bottom: 65%;
}

.gallery-grid-item-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: no-repeat center center/cover;
  display: block;
}

.gallery-modal-panel {
  padding: 95px 115px 36px;
  max-width: none;
}

.gallery-modal-content-info {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}

@media (min-width: 768px) {
  .gallery-grid {
    gap: 25px;
    grid-template-columns: repeat(3, 1fr);
  }
  .gallery-grid-item:nth-child(6n-5),
  .gallery-grid-item:nth-child(6n-1) {
    grid-column: auto/span 2;
    grid-row: auto/span 2;
  }
}
@media (min-width: 1200px) {
  .gallery-tabs {
    margin-bottom: 68px;
  }
  .gallery-grid {
    gap: 50px;
  }
  .gallery-grid-item {
    overflow: hidden;
  }
  .gallery-grid-item-image {
    transition: transform 175ms ease-in;
  }
  .gallery-grid-item-image:hover {
    transform: scale(1.07);
  }
}
.gallery-item-enter-active {
  opacity: 0;
  transition: opacity 275ms ease-in;
}

.gallery-item-enter-to {
  opacity: 1;
}

.gallery-modal-wrapper {
  position: relative;
}

.gallery-modal-leave-active {
  position: absolute;
  opacity: 1;
  transition: opacity 275ms ease-in;
}

.gallery-modal-enter-active {
  opacity: 0;
  transition: opacity 275ms 275ms ease-in;
}

.gallery-modal-enter-to {
  opacity: 1;
}

.gallery-modal-leave-to {
  opacity: 0;
}

#home .site-header-push {
  display: none;
}

.home-banner {
  position: relative;
  background: no-repeat left center/cover;
  display: flex;
  height: 695px;
  max-height: 90vh;
}

.home-banner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.06);
  content: "";
}

.home-banner-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.home-banner-content {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin: auto auto 68px;
  text-align: center;
  z-index: 2;
}

.home-banner-btn {
  position: absolute;
  top: 100%;
  left: 50%;
  border: 3px solid #fff;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (min-width: 768px) {
  .home-banner {
    height: 980px;
  }
  .home-banner-content {
    font-size: 48px;
    margin-bottom: 104px;
  }
}
@media (min-width: 1200px) {
  .home-banner {
    height: 812px;
  }
  .home-banner-content {
    font-size: 60px;
    margin-bottom: 104px;
  }
}
@media (min-width: 1980px) {
  .home-banner {
    height: 980px;
  }
}
.home-title {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 68px;
  text-align: center;
}

.home-features {
  display: grid;
  row-gap: 68px;
}

.home-features img,
.home-features svg {
  height: auto !important;
  max-width: 100%;
}

.home-features-item-content-text,
.home-features-item-content-text img {
  margin-bottom: 20px;
}

.home-features-item-content-text > :last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .home-title {
    font-size: 3rem;
    margin-bottom: 104px;
  }
  .home-features-item {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(12, 1fr);
  }
  .home-features-item-img,
  .home-features-item-content {
    grid-column: auto/span 6;
  }
  .home-features-item:nth-child(odd) .home-features-item-img {
    order: 2;
  }
  .home-features-item:nth-child(odd) .home-features-item-content {
    order: 1;
  }
}
@media (min-width: 1200px) {
  .home-title {
    font-size: 3.75rem;
  }
  .home-features {
    row-gap: 136px;
  }
  .home-features-item-content {
    grid-column: auto/span 5;
  }
  .home-features-item-content-text {
    margin-bottom: 36px;
  }
  .home-features-item:nth-child(odd) .home-features-item-img {
    grid-column-start: 7;
  }
  .home-features-item:nth-child(even) .home-features-item-content {
    grid-column-start: 8;
  }
}
.home-features-space {
  width: 175px;
}

.home-features-elements {
  width: 275px;
}

.home-features-width {
  width: 310px;
}

.home-features-warranty {
  width: 170px;
}

.home-features-australian {
  margin-left: 36px;
  width: 78px;
}

@media (min-width: 768px) {
  .home-features-width {
    width: 275px;
  }
}
@media (min-width: 1200px) {
  .home-features-space {
    width: 260px;
  }
  .home-features-elements {
    width: 360px;
  }
  .home-features-width {
    width: 330px;
  }
  .home-features-warranty {
    width: 230px;
  }
  .home-features-australian {
    width: 103px;
  }
}
@media (min-width: 1980px) {
  .home-features-elements {
    width: 538px;
  }
  .home-features-width {
    width: 460px;
  }
  .home-features-warranty {
    width: 286px;
  }
  .home-features-australian {
    width: 129px;
  }
}
.home-automation {
  background: #53555c;
  color: #fff;
}

.home-automation__background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background: no-repeat center center/cover;
  height: 390px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .home-automation__background {
    height: 880px;
  }
}
@media (min-width: 1200px) {
  .home-automation__background {
    height: 815px;
    padding: 0 85px;
  }
}
@media (min-width: 1980px) {
  .home-automation__background {
    height: 980px;
  }
}

.home-automation__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  content: "";
}

.home-automation__background-icon {
  position: relative;
  flex: 0 0 100px;
  font-size: 1rem;
}
.home-automation__background-icon > svg {
  height: 42px;
  width: 42px;
  margin-bottom: 20px;
}

.home-automation__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}
@media (min-width: 768px) {
  .home-automation__wrapper {
    align-self: center;
  }
}
@media (min-width: 1200px) {
  .home-automation__wrapper {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
}

.home-automation-bubble {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 15px;
  width: 175px;
}

.home-automation-bubble svg {
  fill: rgba(255, 255, 255, 0.8);
  stroke: #ffcd07;
  width: 175px;
}

.home-automation-bubble-text {
  position: absolute;
  top: 50%;
  left: 0;
  color: #53555c;
  padding: 20px 15px 0;
  text-align: center;
  transform: translate(0, -50%);
  text-transform: none;
  width: 100%;
}

.home-automation__content {
  padding-top: 68px;
  padding-bottom: 68px;
  text-align: center;
}
@media (min-width: 768px) {
  .home-automation__content {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .home-automation__content {
    top: 50%;
    bottom: auto;
    left: 0;
    text-align: left;
  }
}

.home-automation__content-sm {
  display: block;
}
@media (min-width: 768px) {
  .home-automation__content-sm {
    display: none;
  }
}

.home-automation__content-md {
  display: none;
}
@media (min-width: 768px) {
  .home-automation__content-md {
    margin-top: 136px;
    display: block;
    order: 2;
  }
}
@media (min-width: 1200px) {
  .home-automation__content-md {
    flex-basis: 0;
    margin-top: 0;
    order: 1;
  }
}

.home-automation__content-md .home-automation-content-inner {
  transform: none;
}

.home-automation-icons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  order: 1;
  margin-top: 36px;
}
@media (min-width: 1200px) {
  .home-automation-icons {
    margin-left: 68px;
    margin-top: 0;
    flex-grow: 1;
    order: 2;
  }
}
.home-automation-icons__bubble {
  position: relative;
  column-gap: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .home-automation-icons__bubble {
    background: rgba(0, 0, 0, 0.36);
    border-radius: 46px;
    padding: 36px;
    column-gap: 32px;
  }
}

.home-automation-content-title {
  font-size: 2.5rem;
  line-height: 1.2;
}

.home-automation-content .btn {
  display: block;
  margin: 36px auto 0;
  width: 100%;
  max-width: 290px;
}

@media (min-width: 768px) {
  .home-automation {
    position: relative;
  }
  .home-automation__background-icon > svg {
    height: 70px;
    width: 70px;
    margin-bottom: 32px;
  }
  .home-automation-content-inner {
    margin: 0 auto;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  .home-automation-bubble {
    margin-top: 32px;
    width: 285px;
  }
  .home-automation-bubble svg {
    width: 285px;
  }
  .home-automation-bubble-text {
    font-size: 1.5625rem;
    padding-top: 35px;
  }
  .home-automation-content-inner {
    margin: 0;
    transform: translate(0, -50%);
    width: 620px;
  }
  .home-automation-content-title {
    font-size: 3.75rem;
  }
  .home-automation__content .btn {
    margin: 36px 0 0;
    max-width: 260px;
  }
}
.home-deeper h2 {
  font-size: 2.5rem;
  text-align: center;
}

.home-deeper .home-features-item-content-text {
  margin-bottom: 36px;
}

.home-deeper .btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 290px;
}

@media (min-width: 768px) {
  .home-deeper h2 {
    text-align: left;
  }
  .home-deeper .home-features-item {
    align-items: center;
  }
  .home-deeper .btn {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .home-deeper h2 {
    font-size: 3.75rem;
  }
  .home-deeper .btn {
    max-width: 260px;
  }
  .home-locator .home-features-item-img {
    padding: 0 50px;
    text-align: center;
  }
}
.home-automation-bubble-bg, .home-features-locator > path, .home-features-locator > g > path, .home-features-australian, .home-features-warranty, .home-features-width-arrow, .home-features-width, .home-features-elements-glare, .home-features-elements-insects, .home-features-elements-weather, .home-features-space-plus, .home-features-space-shelf, .home-features-space-box {
  transition-duration: 500ms;
  transition-timing-function: ease-in;
}

.home-features-space-box {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.animate .home-features-space-box {
  opacity: 1;
}

.home-features-space-shelf {
  opacity: 0;
  transform: translate3d(-5px, 0, 0);
  transition-delay: 500ms;
  transition-property: opacity, transform;
  will-change: opacity, transform;
}
.animate .home-features-space-shelf {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.home-features-space-plus {
  transition-delay: 875ms;
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.animate .home-features-space-plus {
  opacity: 1;
}

.home-features-elements-weather {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.animate .home-features-elements-weather {
  opacity: 1;
}

.home-features-elements-insects {
  opacity: 0;
  transition-delay: 750ms;
  transition-property: opacity;
  will-change: opacity;
}
.animate .home-features-elements-insects {
  opacity: 1;
}

.home-features-elements-glare {
  opacity: 0;
  transition-delay: 1625ms;
  transition-property: opacity;
  will-change: opacity;
}
.animate .home-features-elements-glare {
  opacity: 1;
}

.home-features-width {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.home-features-width.animate {
  opacity: 1;
}

.home-features-width-arrow {
  transform: translate3d(-50px, 0, 0);
  transition-delay: 125ms;
  transition-property: transform;
  will-change: transform;
}
.animate .home-features-width-arrow {
  transform: translate3d(0, 0, 0);
}

.home-features-warranty {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.home-features-warranty.animate {
  opacity: 1;
}

.home-features-australian {
  opacity: 0;
  transition-delay: 750ms;
  transition-property: opacity;
  will-change: opacity;
}
.home-features-australian.animate {
  opacity: 1;
}

.home-features-locator > g > path {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.home-features-locator > g > path:nth-child(1) {
  transition-delay: 0ms;
}
.home-features-locator > g > path:nth-child(2) {
  transition-delay: 50ms;
}
.home-features-locator > g > path:nth-child(3) {
  transition-delay: 100ms;
}
.home-features-locator > g > path:nth-child(4) {
  transition-delay: 150ms;
}
.home-features-locator > g > path:nth-child(5) {
  transition-delay: 200ms;
}
.home-features-locator > g > path:nth-child(6) {
  transition-delay: 250ms;
}
.home-features-locator > g > path:nth-child(7) {
  transition-delay: 300ms;
}
.home-features-locator > g > path:nth-child(8) {
  transition-delay: 350ms;
}
.home-features-locator > g > path:nth-child(9) {
  transition-delay: 400ms;
}
.home-features-locator > g > path:nth-child(10) {
  transition-delay: 450ms;
}
.home-features-locator > g > path:nth-child(11) {
  transition-delay: 500ms;
}
.home-features-locator > g > path:nth-child(12) {
  transition-delay: 550ms;
}
.home-features-locator > g > path:nth-child(13) {
  transition-delay: 600ms;
}
.home-features-locator > g > path:nth-child(14) {
  transition-delay: 650ms;
}
.home-features-locator > g > path:nth-child(15) {
  transition-delay: 700ms;
}
.home-features-locator > g > path:nth-child(16) {
  transition-delay: 750ms;
}
.home-features-locator > g > path:nth-child(17) {
  transition-delay: 800ms;
}
.home-features-locator > g > path:nth-child(18) {
  transition-delay: 850ms;
}
.home-features-locator > g > path:nth-child(19) {
  transition-delay: 900ms;
}
.home-features-locator > g > path:nth-child(20) {
  transition-delay: 950ms;
}
.home-features-locator > g > path:nth-child(21) {
  transition-delay: 1000ms;
}
.home-features-locator > g > path:nth-child(22) {
  transition-delay: 1050ms;
}
.home-features-locator > g > path:nth-child(23) {
  transition-delay: 1100ms;
}
.home-features-locator > g > path:nth-child(24) {
  transition-delay: 1150ms;
}
.home-features-locator > g > path:nth-child(25) {
  transition-delay: 1200ms;
}
.home-features-locator > g > path:nth-child(26) {
  transition-delay: 1250ms;
}
.home-features-locator > g > path:nth-child(27) {
  transition-delay: 1300ms;
}
.home-features-locator > g > path:nth-child(28) {
  transition-delay: 1350ms;
}

.home-features-locator > path {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
  transition-delay: 1250ms;
}

.home-features-locator.animate path {
  opacity: 1;
}

.home-automation-bubble-bg {
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;
}
.animate .home-automation-bubble-bg {
  opacity: 1;
}

.inquiries-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 68px;
}
@media (min-width: 768px) {
  .inquiries-container {
    flex-direction: row;
    max-width: 1120px;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .inquiries-container {
    padding-bottom: 136px;
  }
}

.inquiries-container > h2 {
  text-align: center;
}
@media (min-width: 768px) {
  .inquiries-container > h2 {
    width: 40%;
    text-align: left;
  }
}

.inquiries {
  display: flex;
}

.inquiry {
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
}

.inquiry:nth-child(odd) {
  padding-left: 0;
}

.inquiry:nth-child(even) {
  padding-right: 0;
}

#locator {
  height: 100vh;
  overflow: hidden;
}
#locator .site-footer {
  display: none;
}

.locator-results,
#locator .locator,
#locator .site-main {
  height: calc(100vh - 50px);
}

.locator-search-fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  row-gap: 16px;
}

.locator-search-btn .btn {
  width: 100%;
}

.locator-results {
  display: flex;
  flex-direction: column;
}

.locator-results-fields {
  column-gap: 16px;
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  margin-bottom: 16px;
}

.locator-fields-location,
.locator-fields-distance,
.locator-fields-retailer {
  position: relative;
}

.locator-fields-error {
  position: absolute;
  bottom: 100%;
  left: 50%;
  background-color: rgb(252.3860759494, 239.9139240506, 241.1088607595);
  border: 1px solid #dc3545;
  border-radius: 15px;
  color: rgb(176.1746835443, 30.5253164557, 44.4797468354);
  font-size: 0.9375rem;
  line-height: 20px;
  padding: 4px 14px;
  transform: translate(-50%, -10px);
  white-space: nowrap;
}

.locator-fields-error::before,
.locator-fields-error::after {
  position: absolute;
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  content: "";
  display: block;
}

.locator-fields-error::before {
  border-top-color: #dc3545;
  transform: translate(-50%, 0);
}

.locator-fields-error::after {
  border-top-color: rgb(252.3860759494, 239.9139240506, 241.1088607595);
  transform: translate(-50%, -1px);
}

.locator-fields-location-results {
  position: absolute;
  background: #fff;
  border: 1px solid #ffcd07;
  border-radius: 25px;
  font-size: 0.9375rem;
  margin-top: 7px;
  overflow: hidden;
  width: 100%;
  z-index: 10;
}

.locator-fields-location-results-item {
  padding: 14px 18px;
  line-height: 20px;
}

.locator-results-fields-toggle {
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  display: flex;
  padding: 0;
}

.locator-results-fields-toggle svg {
  margin: auto;
  width: 20px;
}

.locator-results-combined {
  display: grid;
  grid-template-columns: 450px auto;
}

.locator-results-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .locator-results-list {
    padding-left: 30px;
    padding-right: 16px;
  }
}

.locator-results-list-item {
  display: flex;
  font-size: 0.9375rem;
  padding: 14px 0 20px;
}
@media (min-width: 768px) {
  .locator-results-list-item {
    padding: 14px 24px 20px 5px;
  }
}
.locator-results-list-item.is-selected {
  background: rgba(47, 48, 54, 0.04);
}
.locator-results-list-item.is-floating {
  background: none;
  padding: 5px 10px;
}

.locator-results-list-item-number {
  height: 28px;
  width: 28px;
  align-items: center;
  background: #707070;
  border-radius: 14px;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 18px;
}
.is-selected .locator-results-list-item-number {
  background: #ffcd07;
  color: #2f3036;
}
.is-floating .locator-results-list-item-number {
  display: none;
}

.locator-results-list-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.locator-results-list-item-main {
  display: flex;
}

.locator-results-list-item-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.locator-results-list-item-title {
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 5px;
}

.locator-results-list-item-address {
  color: #707070;
}

.locator-results-list-item-distance {
  color: #707070;
  line-height: 1.8;
  margin-left: 15px;
}

.locator-results-list-item-footer {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.locator-results-list-item-contact {
  display: flex;
  column-gap: 10px;
}

.locator-results-list-item-email,
.locator-results-list-item-website {
  font-size: 15px;
  line-height: 20px;
  padding: 4px 12px;
}

.locator-results-list-item-email {
  background-color: #ffcd07;
  border-color: #ffcd07;
  color: #000;
}
.locator-results-list-item-email:hover, .locator-results-list-item-email:focus {
  background-color: #e8bb06;
  border-color: #e8bb06;
  color: #000;
}

.locator-results-list-item-website {
  background: #d7d5d5;
  color: #2f3036;
  font-weight: normal;
}

.locator-results-list-item-website:hover {
  background: #cdcdcd;
  color: #000;
}

.locator-results-list-item + .locator-results-list-item {
  border-top: 1px solid rgba(112, 112, 112, 0.3);
}

.locator-results-list-item-types {
  display: flex;
  margin-left: auto;
}

.locator-results-list-item-types > div + div {
  margin-left: 15px;
}

.locator-results-list-item-types .locator-icon {
  height: 18px;
}

.locator-results-list-item-types .locator-icon-bg {
  opacity: 0;
}

.locator-results-list-item-types .locator-icon:hover .locator-icon-bg {
  opacity: 1;
}

.locator-results-map {
  flex: 1 1 auto;
  height: 100%;
}

.locator-tooltip {
  position: relative;
}

.locator-tooltip-panel {
  position: absolute;
  top: 0;
  right: 50%;
  background: #ffcd07;
  color: #2f3036;
  font-size: 0.8125rem;
  opacity: 0;
  padding: 5px 15px;
  transform: translate(0, calc(-100% - 10px));
  white-space: nowrap;
  z-index: 100;
}
.locator-tooltip:hover .locator-tooltip-panel {
  opacity: 1;
}

.locator-tooltip-panel::before {
  position: absolute;
  top: 100%;
  right: 0;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #ffcd07;
  border-right-color: #ffcd07;
  content: "";
  display: block;
}

@media (max-width: 767.98px) {
  .locator .page-title,
  .locator .page-intro {
    margin-bottom: 50px;
  }
  .locator-results-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
    grid-template-rows: 1fr 1fr;
    row-gap: 14px;
  }
  .locator-results-fields .locator-fields-location {
    grid-column: auto/span 3;
  }
}
@media (min-width: 768px) {
  .locator-results,
  #locator .locator,
  #locator .site-main {
    height: calc(100vh - 68px);
  }
  .locator-search-fields {
    column-gap: 25px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 0 auto 68px;
    max-width: 570px;
  }
  .locator-search-fields .locator-fields-location {
    grid-column: auto/span 2;
  }
  .locator-search-btn {
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;
  }
  .locator-search-btn .btn {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .locator-results-combined {
    height: calc(100vh - 148px);
  }
  .locator-results-fields {
    width: 100%;
    max-width: 1020px;
  }
  .locator-results-fields .locator-fields-location {
    width: 40%;
  }
  .locator-results-fields .locator-fields-retailer,
  .locator-results-fields .locator-fields-distance {
    width: 30%;
  }
}
@media (min-width: 1200px) {
  .locator-results,
  #locator .locator,
  #locator .site-main {
    height: calc(100vh - 105px);
  }
  .locator-search-fields {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: 1120px;
  }
  .locator-results-combined {
    height: calc(100vh - 185px);
  }
  .locator-fields-location-results-item.is-active {
    background: rgba(244, 224, 148, 0.4);
    cursor: pointer;
  }
}
.locator-search-callout {
  background: #707070;
  display: block;
  margin-top: 104px;
}

.locator-search-callout:hover {
  text-decoration: none;
}

.locator-search-callout-text {
  color: #fff;
  font-size: 24px;
  padding: 30px 15px;
  text-align: center;
}

.locator-search-callout-text strong {
  font-weight: 500;
}

@media (min-width: 768px) {
  .locator-search-callout {
    align-items: center;
    display: flex;
    margin: 136px auto 0;
    max-width: 1120px;
  }
  .locator-search-callout-text {
    flex-grow: 1;
    font-size: 30px;
    order: 1;
  }
  .locator-search-callout-image {
    order: 2;
    max-width: 330px;
  }
}
@media (min-width: 1200px) {
  .locator-search-callout-text {
    font-size: 40px;
  }
  .locator-search-callout-image {
    max-width: 365px;
  }
}
@media (min-width: 1980px) {
  .locator-search-callout-image {
    max-width: 375px;
  }
}
.locator-modal .modal-close {
  top: 10px;
  right: 10px;
}

.locator-modal .modal-panel {
  padding-top: 60px;
}

.locator-modal-body-content {
  margin-bottom: 68px;
}

.locator-modal-body-retailers {
  background-clip: padding-box;
  background: #fefbf0;
  border: 1px solid #ffcd07;
  border-radius: 25px;
  color: #53555c;
}

.locator-modal-body-retailers-button {
  appearance: none;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M16.001,24.801c-.411,0-.821-.157-1.135-.47L.47,9.939c-.627-.626-.627-1.643,0-2.27s1.643-.627,2.27,0l13.261,13.257L29.26,7.669c.627-.627,1.643-.627,2.27,0s.627,1.643,0,2.27l-14.394,14.392c-.314,.314-.724,.47-1.135,.47Z%22%20fill=%22%23ffcd07%22%20/%3E%3C/svg%3E") no-repeat center right 20px/20px;
  border: 0;
  color: #53555c;
  display: block;
  font-size: 0.9375rem;
  height: 48px;
  line-height: 20px;
  padding: 14px 38px 14px 18px;
  text-align: left;
  transition-duration: 175ms;
  transition-property: box-shadow, border-color;
  transition-timing-function: ease-in;
  width: 100%;
}
.locator-modal-body-retailers-button:focus {
  outline: none;
}

.locator-modal-body-retailers-content {
  font-size: 0.9375rem;
  padding: 0 18px 14px;
}

.locator-checkbox-group {
  display: flex;
  flex-direction: column;
}

.locator-checkbox {
  align-items: center;
  color: #707070;
  display: flex;
  padding: 18px 4px;
}

.locator-checkbox-input {
  height: 28px;
  width: 28px;
  appearance: none;
  background: transparent;
  border: 1px solid #ffcd07;
  border-radius: 14px;
  flex: 0 0 28px;
}

.locator-checkbox-input:checked {
  background: #ffcd07 url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M31.072,2.561c-1.076-.879-2.644-.706-3.506,.38L11.239,23.484,4.41,15.177c-.884-1.072-2.452-1.215-3.514-.323-1.057,.896-1.2,2.489-.319,3.563l8.775,10.675c.053,.064,.129,.087,.186,.144,.063,.064,.096,.146,.167,.204,.108,.088,.234,.13,.35,.197,.095,.054,.18,.117,.28,.158,.302,.125,.615,.206,.933,.206,0,0,0,0,.001,0s0,0,.001,0c.325,0,.645-.084,.953-.214,.099-.042,.181-.104,.275-.159,.123-.071,.255-.117,.368-.212,.073-.062,.107-.149,.171-.217,.053-.055,.126-.077,.174-.139L31.447,6.117c.864-1.089,.696-2.679-.375-3.556Z%22%20fill=%22%2353555c%22%20/%3E%3C/svg%3E") no-repeat center center/13px;
}

.locator-checkbox-label {
  margin-left: 22px;
}

@media (min-width: 768px) {
  .locator-modal-body {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 25px;
  }
  .locator-modal-body-form {
    grid-column: auto/span 2;
    margin-bottom: 0;
    order: 1;
  }
  .locator-modal-body-content {
    order: 2;
  }
  .locator-modal-body-form .form-group,
  .locator-modal-body-form .form-button {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .locator-modal-body-form .form-control {
    grid-column: auto/span 3;
  }
  .locator-modal-body-form .locator-modal-body-offset {
    grid-column: 2/span 3;
  }
  .locator-modal-body-form .form-label {
    margin: 15px 0 0;
  }
  .locator-modal-body-form .form-button {
    margin-top: 68px;
  }
}
@media (min-width: 1200px) {
  .locator-modal-body {
    column-gap: 50px;
  }
}
.news-hero {
  margin-bottom: 68px;
}

.news-hero-image {
  display: block;
}

.news-hero-content-title > a,
.news-hero-content-title > a:hover,
.news-grid-item-title > a,
.news-grid-item-title > a:hover {
  color: inherit;
  text-decoration: none;
}

.news-hero-content-excerpt {
  margin-bottom: 25px;
}

.news-grid {
  display: grid;
  gap: 68px;
}

.news-grid-item-image {
  display: block;
  margin-bottom: 24px;
}

.news-grid-item-title {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 26px;
}

.news-hero-content-excerpt > :last-child,
.news-grid-item-excerpt > :last-child {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .news-hero-image {
    margin-bottom: 36px;
  }
  .news-hero-content-title {
    font-size: 1.375rem;
  }
  .news-hero-content-more {
    display: none;
  }
}
@media (min-width: 768px) {
  .news-hero {
    display: grid;
    column-gap: 25px;
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .news-hero-image {
    grid-column: auto/span 5;
  }
  .news-hero-content {
    grid-column: auto/span 4;
  }
  .news-grid {
    column-gap: 25px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .news-hero,
  .news-grid {
    grid-gap: 50px;
  }
  .news-hero {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin-bottom: 104px;
  }
  .news-hero-image {
    grid-column: auto/span 7;
  }
  .news-hero-content {
    grid-column: auto/span 5;
  }
  .news-grid-item-title {
    font-size: 2.125rem;
  }
}
@media (min-width: 1980px) {
  .news-hero,
  .news-grid {
    column-gap: 90px;
  }
  .news-grid-item-title {
    font-size: 2.5rem;
  }
}
.product-page-padding {
  padding-top: 36px !important;
}

.product {
  display: grid;
  column-gap: 25px;
  row-gap: 68px;
}

.product-intro {
  display: flex;
  flex-direction: column;
  margin-bottom: 68px;
}
@media (min-width: 768px) {
  .product-intro {
    flex-direction: row;
    gap: 25px;
  }
}
@media (min-width: 1200px) {
  .product-intro {
    margin-bottom: 136px;
  }
}

.product-intro .product-gallery {
  margin-bottom: 68px;
}
@media (min-width: 768px) {
  .product-intro .product-gallery {
    width: 50%;
    margin-bottom: 0;
  }
}

.product-intro .page-title {
  font-size: 2.5rem;
  margin-bottom: 36px;
}

.product-intro img {
  margin-bottom: 36px;
}

.product-heading {
  border-bottom: 1px solid #707070;
  padding-bottom: 10px;
}

.product-intro-content {
  margin-bottom: 36px;
}
@media (min-width: 768px) {
  .product-intro-content {
    width: 50%;
  }
}
.product-intro-content .page-title {
  text-align: left;
}

@media (min-width: 768px) {
  .product {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .product-wide {
    grid-column: auto/span 2;
  }
  .product .page-title {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .product {
    column-gap: 50px;
    row-gap: 136px;
  }
  .product-intro .page-title {
    margin-bottom: 68px;
  }
  .product-heading {
    margin-bottom: 68px;
  }
}
@media (min-width: 1980px) {
  .product {
    column-gap: 312px;
  }
}
.product-configurations {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}

.product-configurations figcaption {
  text-align: center;
}

@media (min-width: 768px) {
  .product-configurations {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .product-configurations {
    column-gap: 68px;
    row-gap: 136px;
  }
}
.product-installations {
  display: grid;
  gap: 30px;
}

.product-installations figcaption {
  text-align: center;
}

@media (min-width: 768px) {
  .product-installations {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .product-installations {
    column-gap: 68px;
    row-gap: 136px;
  }
}
.product-dimensions {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 36px;
}

.product-dimensions-item {
  flex: 1;
}

.product-dimensions figcaption {
  margin-top: 0;
  margin-bottom: 26px;
}

@media (min-width: 768px) {
  .product-dimensions {
    display: flex;
  }
}
.product-colours {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  text-align: center;
}

.product-colours-item img {
  height: auto !important;
  max-width: 100%;
  max-width: 70px;
}

@media (min-width: 1200px) {
  .product-colours {
    gap: 35px;
  }
  .product-colours-item img {
    max-width: 85px;
  }
}
@media (min-width: 1980px) {
  .product-colours-item img {
    max-width: 105px;
  }
}
.product-controls {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  text-align: center;
}

.product-controls-item svg {
  height: 85px;
}

@media (min-width: 1200px) {
  .product-controls {
    gap: 35px;
  }
  .product-controls-item svg {
    height: 110px;
  }
}
@media (min-width: 1980px) {
  .product-controls-item svg {
    max-width: 140px;
  }
}
.product-explore {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 25px;
}

.product-explore-item-image-wrapper {
  margin: 0 auto 15px;
  max-width: 160px;
}

.product-explore-item-image {
  position: relative;
  border-radius: 100%;
  background: #fff;
  overflow: hidden;
  display: flex;
  width: 100%;
  padding-bottom: 100%;
}

.product-explore-item-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 75%;
  max-width: 75%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.product-explore-item-title {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 36px;
}

.product-explore-item-excerpt {
  font-size: 14px;
  line-height: 1.75;
}

@media (min-width: 768px) {
  .product-explore {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .product-explore {
    gap: 50px;
  }
  .product-explore-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 42px;
    width: 42px;
    background: #2f3036 url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M13.377,0V13.443H0v5.146H13.377v13.411h5.244v-13.411h13.379v-5.146h-13.377V0h-5.246Z%22%20fill=%22%23ffcd07%22/%3E%3C/svg%3E") no-repeat center center/18px;
    border-radius: 42px;
    content: "";
    display: block;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 350ms ease-in-out;
    z-index: 10;
  }
  .product-explore-item-image:hover::before {
    opacity: 1;
  }
  .product-explore-item-title {
    font-size: 16px;
    font-weight: 500;
  }
  .product-explore-item-excerpt {
    font-size: 14px;
  }
}
.products-title {
  margin-bottom: 36px;
}

.products-hero {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  margin-bottom: 68px;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .products-hero {
    flex-direction: row;
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .products-hero {
    margin-top: -50px;
    border-radius: 10px;
    margin-bottom: 104px;
  }
}

.products-hero-content {
  align-self: center;
  padding: 68px 36px;
}

.products-grid-heading {
  text-align: center;
}

.products-grid {
  column-gap: 25px;
  display: grid;
  margin-bottom: 104px;
  row-gap: 36px;
}

.products-hero-item-image,
.products-grid-item-image {
  cursor: pointer;
  display: block;
  margin-bottom: 25px;
}

.products-hero-item-title,
.products-grid-item-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.products-hero-item-excerpt > :last-child,
.products-grid-item-excerpt > :last-child {
  margin-bottom: 0;
}

.products-hero-item-title {
  font-weight: 400;
}

.products-hero-item-excerpt {
  margin-bottom: 36px;
}

.products-grid-item-title {
  font-weight: 500;
}

.products-grid-item-excerpt {
  margin-bottom: 16px;
}

.products-hero-item-image {
  margin-bottom: 0;
}

.products-hero-item-image img {
  border-radius: 5px 5px 0 0;
}
@media (min-width: 768px) {
  .products-hero-item-image img {
    width: 532px;
    border-radius: 10px 0 0 10px;
  }
}

.products-grid-hero-more {
  padding-top: 9px;
  padding-bottom: 9px;
  min-width: 160px;
}

.products-grid-item-more {
  padding-top: 9px;
  padding-bottom: 9px;
  min-width: 160px;
}

@media (min-width: 768px) {
  .products-hero-item-image img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
  .products-grid-heading {
    margin-bottom: 68px;
  }
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 68px;
  }
}
@media (min-width: 1200px) {
  .products-grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    margin-bottom: 136px;
  }
  .products-hero-item-image {
    margin-bottom: 0;
    max-width: 600px;
  }
  .products-hero-item-image,
  .products-grid-item-image {
    position: relative;
  }
  .products-grid-item-image {
    margin-bottom: 50px;
  }
  .products-hero-item-image::before,
  .products-grid-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 85px;
    width: 85px;
    background: #2f3036 url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2032%2032%22%20width=%2232%22%20height=%2232%22%3E%3Cpath%20d=%22M13.377,0V13.443H0v5.146H13.377v13.411h5.244v-13.411h13.379v-5.146h-13.377V0h-5.246Z%22%20fill=%22%23ffcd07%22/%3E%3C/svg%3E") no-repeat center center/36px;
    border-radius: 85px;
    content: "";
    display: block;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 350ms ease-in-out;
  }
  .products-hero-item-image:hover::before,
  .products-grid-item-image:hover::before {
    opacity: 1;
  }
  .products-title {
    margin-bottom: 24px;
  }
  .products-hero-item-title {
    margin-bottom: 68px;
  }
}
.products-learn {
  display: block;
  background: #ffcd07;
}

.products-learn:hover {
  text-decoration: none;
}

.products-learn-text {
  color: #fff;
  font-size: 24px;
  padding: 30px 15px;
  text-align: center;
}

@media (min-width: 768px) {
  .products-learn {
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 1120px;
  }
  .products-learn-text {
    flex-grow: 1;
    font-size: 30px;
    order: 1;
  }
  .products-learn-image {
    order: 2;
    max-width: 330px;
  }
}
@media (min-width: 1200px) {
  .products-learn-text {
    font-size: 40px;
  }
  .products-learn-image {
    max-width: 365px;
  }
}
@media (min-width: 1980px) {
  .products-learn-image {
    max-width: 375px;
  }
}
.support-guide {
  display: grid;
  row-gap: 68px;
  counter-reset: section;
}

.support-guide img {
  height: auto !important;
  max-width: 100%;
}

.support-guide-item {
  counter-increment: section;
}

.support-guide-item-content {
  margin-bottom: 20px;
}

.support-guide-item-content strong {
  font-weight: 500;
}

.support-guide-item-content > :first-child::before {
  content: counter(section) ". ";
  font-weight: 500;
}

.support-guide-item-content > :last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .support-guide-item {
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(12, 1fr);
  }
  .support-guide-item-img,
  .support-guide-item-content {
    grid-column: auto/span 6;
  }
  .support-guide-item-content {
    margin-bottom: 0;
  }
  .support-guide-item:nth-child(even) .support-guide-item-img {
    order: 1;
  }
  .support-guide-item:nth-child(even) .support-guide-item-content {
    order: 2;
  }
}
@media (min-width: 1200px) {
  .support-guide {
    row-gap: 136px;
  }
  .support-guide-item-content {
    grid-column: auto/span 5;
  }
  .support-guide-item:nth-child(even) .support-guide-item-content {
    grid-column-start: 8;
  }
  .support-guide-item:nth-child(odd) .support-guide-item-img {
    grid-column-start: 7;
  }
}
.support-callout {
  position: relative;
  display: block;
}

.support-callout:hover {
  text-decoration: none;
}

.support-callout-text {
  background: #e6e5e5;
  color: #53555c;
  font-size: 24px;
}

.support-callout-text-inner {
  padding: 30px 15px;
  text-align: center;
}

@media (max-width: 767.98px) {
  .support-callout {
    padding-top: 16%;
  }
  .support-callout-text {
    padding-top: 46%;
  }
  .support-callout-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .support-callout {
    margin: 0 auto;
    max-width: 1120px;
  }
  .support-callout-text {
    font-size: 30px;
    padding-right: 330px;
  }
  .support-callout-text-inner {
    padding-left: 30px;
    padding-right: 0;
    text-align: center;
  }
  .support-callout-image {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 330px;
  }
}
@media (min-width: 1200px) {
  .support-callout-text {
    font-size: 40px;
    padding-right: 365px;
  }
  .support-callout-image {
    max-width: 365px;
  }
}
@media (min-width: 1980px) {
  .support-callout-text {
    padding-right: 375px;
  }
  .support-callout-image {
    max-width: 375px;
  }
}