//
// Home page animations
//

$duration: 500ms

%animated-element
    transition-duration: $duration
    transition-timing-function: ease-in

//
// Space icon
//

.home-features-space-box
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity

    .animate &
        opacity: 1

.home-features-space-shelf
    @extend %animated-element
    opacity: 0
    transform: translate3d(-5px, 0, 0)
    transition-delay: $duration
    transition-property: opacity, transform
    will-change: opacity, transform

    .animate &
        opacity: 1
        transform: translate3d(0, 0, 0)

.home-features-space-plus
    @extend %animated-element
    transition-delay: $duration * 1.75
    opacity: 0
    transition-property: opacity
    will-change: opacity

    .animate &
        opacity: 1

//
// Element protection icon
//

.home-features-elements-weather
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity

    .animate &
        opacity: 1

.home-features-elements-insects
    @extend %animated-element
    opacity: 0
    transition-delay: $duration * 1.5
    transition-property: opacity
    will-change: opacity

    .animate &
        opacity: 1

.home-features-elements-glare
    @extend %animated-element
    opacity: 0
    transition-delay: $duration * 3.25
    transition-property: opacity
    will-change: opacity

    .animate &
        opacity: 1

//
// Width icon
//

.home-features-width
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity

    &.animate
        opacity: 1

.home-features-width-arrow
    @extend %animated-element
    transform: translate3d(-50px, 0, 0)
    transition-delay: $duration * 0.25
    transition-property: transform
    will-change: transform

    .animate &
        transform: translate3d(0, 0, 0)

//
// Premium design icons
//

.home-features-warranty
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity

    &.animate
        opacity: 1

.home-features-australian
    @extend %animated-element
    opacity: 0
    transition-delay: $duration * 1.5
    transition-property: opacity
    will-change: opacity

    &.animate
        opacity: 1

//
// Premium locator icon
//

.home-features-locator > g > path
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity

    @for $n from 1 through 28
        &:nth-child(#{$n})
            transition-delay: $duration * 0.1 * ($n - 1)

.home-features-locator > path
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity
    transition-delay: $duration * 2.5

.home-features-locator.animate path
    opacity: 1

//
// Automation bubble
//

.home-automation-bubble-bg
    @extend %animated-element
    opacity: 0
    transition-property: opacity
    will-change: opacity

    .animate &
        opacity: 1
