@use "../global/variables";
@use "../mixins/breakpoints";

//
// News section
//
.news-hero {
    margin-bottom: variables.$spacer-2;
}

.news-hero-image {
    display: block;
}

.news-hero-content-title,
.news-grid-item-title {
    > a,
    > a:hover {
        color: inherit;
        text-decoration: none;
    }
}

.news-hero-content-excerpt {
    margin-bottom: 25px;
}

.news-grid {
    display: grid;
    gap: variables.$spacer-2;
}

.news-grid-item-image {
    display: block;
    margin-bottom: 24px;
}

.news-grid-item-title {
    font-size: 1.375rem;
    font-weight: variables.$font-weight-medium;
    line-height: 1.2;
    margin-bottom: 26px;
}

.news-hero-content-excerpt > :last-child,
.news-grid-item-excerpt > :last-child {
    margin-bottom: 0;
}

@include breakpoints.media-breakpoint-down(sm) {
    .news-hero-image {
        margin-bottom: variables.$spacer-1;
    }

    .news-hero-content-title {
        font-size: 1.375rem;
    }

    .news-hero-content-more {
        display: none;
    }
}

@include breakpoints.media-breakpoint-up(md) {
    .news-hero {
        display: grid;
        column-gap: 25px;
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }

    .news-hero-image {
        grid-column: auto / span 5;
    }

    .news-hero-content {
        grid-column: auto / span 4;
    }

    .news-grid {
        column-gap: 25px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@include breakpoints.media-breakpoint-up(lg) {
    .news-hero,
    .news-grid {
        grid-gap: 50px;
    }

    .news-hero {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        margin-bottom: variables.$spacer-3;
    }

    .news-hero-image {
        grid-column: auto / span 7;
    }

    .news-hero-content {
        grid-column: auto / span 5;
    }

    .news-grid-item-title {
        font-size: 2.125rem;
    }
}

@include breakpoints.media-breakpoint-up(xl) {
    .news-hero,
    .news-grid {
        column-gap: 90px;
    }

    .news-grid-item-title {
        font-size: 2.5rem;
    }
}

