@use "../global/variables";
@use "../mixins";

//
// Home page
//
#home .site-header-push {
  display: none;
}

//
// Home banner
//

.home-banner {
  @include mixins.relative();
  background: no-repeat left center / cover;
  display: flex;
  height: 695px;
  max-height: 90vh;
}

.home-banner::before {
  @include mixins.absolute(top left bottom right);
  background: rgba(variables.$black, 0.06);
  content: "";
}

.home-banner-video {
  @include mixins.absolute(top left);
  @include mixins.size(100%);
  object-fit: cover;
}

.home-banner-overlay {
  @include mixins.absolute(top left);
  @include mixins.size(100%);
  background: rgba(variables.$black, 0.15);
  z-index: 1;
}

.home-banner-content {
  @include mixins.relative();
  @include mixins.font-smoothing();
  color: variables.$white;
  font-size: 40px;
  font-weight: variables.$font-weight-medium;
  line-height: 1.2;
  margin: auto auto variables.$spacer-2;
  text-align: center;
  z-index: 2;
}

.home-banner-btn {
  @include mixins.absolute(top 100% left 50%);
  border: 3px solid variables.$white;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@include mixins.media-breakpoint-up(md) {
  .home-banner {
    height: 980px;
  }

  .home-banner-content {
    font-size: 48px;
    margin-bottom: variables.$spacer-3;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .home-banner {
    height: 812px;
  }

  .home-banner-content {
    font-size: 60px;
    margin-bottom: variables.$spacer-3;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .home-banner {
    height: 980px;
  }
}

//
// Home features
//

.home-title {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: variables.$spacer-2;
  text-align: center;
}

.home-features {
  display: grid;
  row-gap: variables.$spacer-2;
}

.home-features img,
.home-features svg {
  @include mixins.img-fluid();
}

.home-features-item-content-text,
.home-features-item-content-text img {
  margin-bottom: 20px;
}

.home-features-item-content-text > :last-child {
  margin-bottom: 0;
}

@include mixins.media-breakpoint-up(md) {
  .home-title {
    font-size: 3rem;
    margin-bottom: variables.$spacer-3;
  }

  .home-features-item {
    display: grid;
    column-gap: variables.$grid-gutter-width;
    grid-template-columns: repeat(12, 1fr);
  }

  .home-features-item-img,
  .home-features-item-content {
    grid-column: auto / span 6;
  }

  .home-features-item:nth-child(odd) .home-features-item-img {
    order: 2;
  }

  .home-features-item:nth-child(odd) .home-features-item-content {
    order: 1;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .home-title {
    font-size: 3.75rem;
  }

  .home-features {
    row-gap: variables.$spacer-4;
  }

  .home-features-item-content {
    grid-column: auto / span 5;
  }

  .home-features-item-content-text {
    margin-bottom: variables.$spacer-1;
  }

  .home-features-item:nth-child(odd) .home-features-item-img {
    grid-column-start: 7;
  }

  .home-features-item:nth-child(even) .home-features-item-content {
    grid-column-start: 8;
  }
}

//
// Home features icons
//

.home-features-space {
  width: 175px;
}

.home-features-elements {
  width: 275px;
}

.home-features-width {
  width: 310px;
}

.home-features-warranty {
  width: 170px;
}

.home-features-australian {
  margin-left: variables.$spacer-1;
  width: 78px;
}

@include mixins.media-breakpoint-up(md) {
  .home-features-width {
    width: 275px;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .home-features-space {
    width: 260px;
  }

  .home-features-elements {
    width: 360px;
  }

  .home-features-width {
    width: 330px;
  }

  .home-features-warranty {
    width: 230px;
  }

  .home-features-australian {
    width: 103px;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .home-features-elements {
    width: 538px;
  }

  .home-features-width {
    width: 460px;
  }

  .home-features-warranty {
    width: 286px;
  }

  .home-features-australian {
    width: 129px;
  }
}

//
// Home automation
//

.home-automation {
  background: variables.$gray-700;
  color: variables.$white;
}

.home-automation__background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include mixins.relative();
  background: no-repeat center center / cover;
  height: 390px;
  overflow: hidden;
  @include mixins.media-breakpoint-up(md) {
    height: 880px;
  }
  @include mixins.media-breakpoint-up(lg) {
    height: 815px;
    padding: 0 variables.$container-gutter-lg;
  }
  @include mixins.media-breakpoint-up(xl) {
    height: 980px;
  }
}

.home-automation__background::before {
  @include mixins.absolute(top left bottom right);
  background: rgba(variables.$black, 0.2);
  content: "";
}

.home-automation__background-icon {
  @include mixins.relative();
  flex: 0 0 100px;
  font-size: 1rem;

  > svg {
    @include mixins.size(42px);
    margin-bottom: 20px;
  }
}

.home-automation__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  @include mixins.media-breakpoint-up(md) {
    align-self: center;
  }
  @include mixins.media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
}

.home-automation-bubble {
  @include mixins.absolute(top 100% left 50%);
  margin-top: 15px;
  width: 175px;
}

.home-automation-bubble svg {
  fill: rgba(variables.$white, 0.8);
  stroke: variables.$brand;
  width: 175px;
}

.home-automation-bubble-text {
  @include mixins.absolute(top 50% left);
  color: variables.$gray-700;
  padding: 20px 15px 0;
  text-align: center;
  transform: translate(0, -50%);
  text-transform: none;
  width: 100%;
}

.home-automation__content {
  padding-top: variables.$spacer-2;
  padding-bottom: variables.$spacer-2;
  text-align: center;
  @include mixins.media-breakpoint-up(md) {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
  @include mixins.media-breakpoint-up(lg) {
    @include mixins.position(left top 50% bottom auto);
    text-align: left;
  }
}

.home-automation__content-sm {
  display: block;
  @include mixins.media-breakpoint-up(md) {
    display: none;
  }
}

.home-automation__content-md {
  display: none;
  @include mixins.media-breakpoint-up(md) {
    margin-top: variables.$spacer-4;
    display: block;
    order: 2;
  }
  @include mixins.media-breakpoint-up(lg) {
    flex-basis: 0;
    margin-top: 0;
    order: 1;
  }
}

.home-automation__content-md .home-automation-content-inner {
  transform: none;
}

.home-automation-icons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  order: 1;
  margin-top: variables.$spacer-1;
  @include mixins.media-breakpoint-up(lg) {
    margin-left: variables.$spacer-2;
    margin-top: 0;
    flex-grow: 1;
    order: 2;
  }
  &__bubble {
    @include mixins.relative();
    column-gap: 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    @include mixins.media-breakpoint-up(md) {
      background: rgba(0,0,0,0.36);
      border-radius: 46px;
      padding: variables.$spacer-1;
      column-gap: 32px;
    }
  }
}

.home-automation-content-title {
  font-size: 2.5rem;
  line-height: 1.2;
}

.home-automation-content .btn {
  display: block;
  margin: variables.$spacer-1 auto 0;
  width: 100%;
  max-width: 290px;
}

@include mixins.media-breakpoint-up(md) {
  .home-automation {
    @include mixins.relative();
  }

  .home-automation__background-icon > svg {
    @include mixins.size(70px);
    margin-bottom: 32px;
  }

  .home-automation-content-inner {
    margin: 0 auto;
    width: 520px;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .home-automation-bubble {
    margin-top: 32px;
    width: 285px;
  }

  .home-automation-bubble svg {
    width: 285px;
  }

  .home-automation-bubble-text {
    font-size: 1.5625rem;
    padding-top: 35px;
  }

  .home-automation-content-inner {
    margin: 0;
    transform: translate(0, -50%);
    width: 620px;
  }

  .home-automation-content-title {
    font-size: 3.75rem;
  }

  .home-automation__content .btn {
    margin: variables.$spacer-1 0 0;
    max-width: 260px;
  }
}

//
// Home deeper
//

.home-deeper h2 {
  font-size: 2.5rem;
  text-align: center;
}

.home-deeper .home-features-item-content-text {
  margin-bottom: variables.$spacer-1;
}

.home-deeper .btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 290px;
}

@include mixins.media-breakpoint-up(md) {
  .home-deeper h2 {
    text-align: left;
  }

  .home-deeper .home-features-item {
    align-items: center;
  }

  .home-deeper .btn {
    margin: 0;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .home-deeper h2 {
    font-size: 3.75rem;
  }

  .home-deeper .btn {
    max-width: 260px;
  }

  .home-locator .home-features-item-img {
    padding: 0 50px;
    text-align: center;
  }
}
