@use "../global/variables";
@use "../mixins/breakpoints";

.container {
    padding-left: variables.$container-gutter-sm;
    padding-right: variables.$container-gutter-sm;
    width: 100%;

    @include breakpoints.media-breakpoint-up(lg) {
        padding-left: variables.$container-gutter-lg;
        padding-right: variables.$container-gutter-lg;
    }

    @media (min-width: variables.$container-max-width) {
        padding-left: calc(50vw - #{variables.$container-max-width * 0.5 - variables.$container-gutter-lg});
        padding-right: calc(50vw - #{variables.$container-max-width * 0.5 - variables.$container-gutter-lg});
    }
}
