@use "../global/variables";
@use "../mixins/breakpoints";
@use "sass:map";

//
// Hidden utilities
//
@each $bp in map.keys(variables.$grid-breakpoints) {
    .hidden-#{$bp}-up {
        @include breakpoints.media-breakpoint-up($bp) {
            display: none !important;
        }
    }

    .hidden-#{$bp}-down {
        @include breakpoints.media-breakpoint-down($bp) {
            display: none !important;
        }
    }
}
