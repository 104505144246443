@use '../global/variables';
@use '../mixins/breakpoints';

//
// Headings
//

body {
  font-size: 1rem;
  font-family: variables.$font-family-sans-serif;
  font-weight: variables.$font-weight-normal;
  line-height: 1.375;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: variables.$font-weight-medium;
  margin-bottom: variables.$spacer-1;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: variables.$spacer-2;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

@include breakpoints.media-breakpoint-up(md) {
  h1 {
    font-size: 3rem;
  }
}

@include breakpoints.media-breakpoint-up(lg) {
  body {
    font-size: 1.25rem;
    line-height: 1.35;
  }

  h1 {
    font-size: 3.75rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.375rem;
  }

  h6 {
    font-size: 1.125rem;
  }
}

//
// Horizontal rules
//

hr {
  border: 0;
  border-top: 1px solid variables.$border-color;
  margin-top: variables.$paragraph-margin-bottom;
  margin-bottom: variables.$paragraph-margin-bottom;
}

//
// Emphasis
//

small {
  font-size: variables.$small-font-size;
  font-weight: variables.$font-weight-normal;
}

//
// Emphasis
//

figcaption {
  font-size: 14px;
  margin-top: 10px;
}

@include breakpoints.media-breakpoint-up(lg) {
  figcaption {
    font-size: 20px;
    margin-top: 25px;
  }
}
