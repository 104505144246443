@use "sass:list";
@use "sass:math";
@use "sass:meta";

@function is-valid-length($value) {
    @return (meta.type-of($value) == "number" and not math.is-unitless($value)) or (list.index(auto initial inherit 0, $value) != null)
}

@mixin position($args: ()) {
    $offsets: top right bottom left;
    @each $offset in $offsets {
        $index: list.index($args, $offset);

        @if $index {
            @if $index == list.length($args) {
                #{$offset}: 0;
            } @else {
                $next: list.nth($args, $index + 1);
                @if is-valid-length($next) {
                    #{$offset}: $next;
                } @else if list.index($offsets, $next) {
                    #{$offset}: 0;
                } @else {
                    @warn "Invalid value `#{$next}` for offset `#{$offset}`.";
                }
            }
        }
    }
}




// Shorthands
// ---

@mixin absolute($args: ()) {
    position: absolute;
    @include position($args);
}

@mixin fixed($args: ()) {
    position: fixed;
    @include position($args);
}

@mixin relative($args: ()) {
    position: relative;
    @include position($args);
}
