@use "../global/variables";
@use "../mixins/breakpoints";

//
// Deep Dive pages
//
.deep-content {
    .page-section-grey,
    .page-section-light {
        overflow: hidden;
    }

    .deep-content-title + .page-section-light {
        margin-top: -1 * variables.$spacer-2;
    }
}

.deep-feature-left,
.deep-feature-right {
    align-items: center;
    display: grid;
}

.deep-grid-three > div + div {
    margin-top: variables.$spacer-1;
}

@include breakpoints.media-breakpoint-down(sm) {
    .deep-feature-left,
    .deep-feature-right {
        h2 {
            text-align: center;
        }

        img,
        .video {
            margin: 0 auto variables.$spacer-1;
        }
    }

    .deep-feature-left,
    .deep-feature-right {
        > :nth-child(1) {
            order: 2;
        }

        > :nth-child(2) {
            order: 1;
        }
    }
}

@include breakpoints.media-breakpoint-up(md) {
    .deep-feature-left,
    .deep-feature-right {
        column-gap: 35px;
        grid-template-columns: repeat(2, 1fr);
    }

    .deep-feature-left {
        > :nth-child(1) {
            order: 2;
        }

        > :nth-child(2) {
            order: 1;
        }
    }

    .deep-grid-three > div + div {
        margin-top: variables.$spacer-2;
    }
}

@include breakpoints.media-breakpoint-up(lg) {
    .deep-content {
        .page-section-grey + .page-section-grey,
        .page-section-light + .page-section-light {
            margin-top: -1 * variables.$spacer-4;
            padding-top: variables.$spacer-3;
        }

        .deep-content-title + .page-section-light {
            padding-top: 0;
        }
    }

    .deep-feature-left,
    .deep-feature-right {
        column-gap: 50px;
    }

    .deep-grid-three {
        display: grid;
        column-gap: 50px;
        grid-template-columns: repeat(3, 1fr);
    }

    .deep-grid-three > div + div {
        margin-top: 0;
    }
}
