@use "../global/variables";
@use "../mixins";

//
// Site header component
//
.site-header {
    @include mixins.fixed(top);
    align-items: center;
    //backdrop-filter: blur(3px);
    background: rgba(variables.$white, 0.7);
    color: variables.$gray-900;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    transition-duration: 125ms;
    transition-property: background, color, padding;
    transition-timing-function: ease-in;
    z-index: 10;

    .header-dark & {
        @include mixins.font-smoothing();
        background: rgba(variables.$black, 0.4);
        color: variables.$white;
    }

    .header-collapse & {
        padding-top: 7px;
        padding-bottom: 8px;
    }
}

.site-header-push {
    height: 110px;
}

.site-header-logo,
.site-header-logo svg {
    @include mixins.size(156px, 28px);
}

.site-header-logo {
    @include mixins.relative();
    display: block;
}

.site-header-logo svg {
    @include mixins.absolute(top left);
    transition: opacity 125ms ease-in;
}

.site-header-logo-dark {
    opacity: 1;

    .header-dark & {
        opacity: 0;
    }
}

.site-header-logo-light {
    opacity: 0;

    .header-dark & {
        opacity: 1;
    }
}

.site-header-menu-toggle {
    @include mixins.relative();
    @include mixins.size(28px);
    appearance: none;
    background: none;
    border: 0;
    padding: 0;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.site-header-btn {
    display: none;
    font-size: 15px;
    margin-right: 36px;
    padding: 6px 12px;
    line-height: 20px;
    width: 160px;
}

@include mixins.media-breakpoint-down(md) {
    .site-header-push {
        height: 50px;
    }

    .site-header,
    .header-dark .site-header,
    .header-collapse .site-header {
        backdrop-filter: none;
        background: none;
        padding: 0;
    }

    .site-header-mobile {
        //backdrop-filter: blur(1px);
        background: rgba(variables.$black, 0.3);
        display: flex;
        align-items: center;
        padding-top: 11px;
        padding-bottom: 11px;
        transition: background 275ms ease-in;
        transition-delay: variables.$mobile-menu-bg-delay-out;
        z-index: 10;

        .menu-active & {
            background: variables.$gray-700;
            transition-delay: variables.$mobile-menu-bg-delay-in;
        }
    }

    .site-header-logo {
        margin-right: auto;
    }

    .site-header-logo-dark {
        opacity: 0;
    }

    .site-header-logo-light {
        opacity: 1;
    }

    .site-header-menu-toggle-bar {
        @include mixins.size(28px, 2px);
        background: variables.$white;
        border-radius: 1px;
        display: block;
        transition-property: background, transform, opacity;
        transition-duration: 375ms;
        transition-timing-function: ease-out;

        &.open-1 {
            @include mixins.absolute(top 2px);
            transition-delay: variables.$mobile-menu-bg-delay-out + 150ms;
        }

        &.open-2 {
            @include mixins.absolute(top 13px);
            transition-delay: variables.$mobile-menu-bg-delay-out + 0ms;
        }

        &.open-3 {
            @include mixins.absolute(top 24px);
            transition-delay: variables.$mobile-menu-bg-delay-out + 300ms;
        }

        .menu-active &.open-1,
        .menu-active &.open-2,
        .menu-active &.open-3 {
            transform: translate(20px, 0);
            opacity: 0;
        }

        .menu-active &.open-1 {
            transition-delay: variables.$mobile-menu-bg-delay-in + 150ms;
        }

        .menu-active &.open-2 {
            transition-delay: variables.$mobile-menu-bg-delay-in + 0ms;
        }

        .menu-active &.open-3 {
            transition-delay: variables.$mobile-menu-bg-delay-in + 300ms;
        }

        &.close-1 {
            @include mixins.absolute(top 4px left 4px);
            opacity: 0;
            transform: rotate(45deg) translate(-20px, 0);
            transform-origin: left;
            transition-delay: 150ms;
        }

        &.close-2 {
            @include mixins.absolute(top 4px right 4px);
            opacity: 0;
            transform: rotate(-45deg) translate(20px, 0);
            transform-origin: right;
            transition-delay: 0ms;
        }

        .menu-active &.close-1,
        .menu-active &.close-2 {
            opacity: 1;
        }

        .menu-active &.close-1 {
            transform: rotate(45deg);
            transition-delay: 150ms + 375ms;
        }

        .menu-active &.close-2 {
            transform: rotate(-45deg);
            transition-delay: 0ms + 375ms;
        }
    }
}

@include mixins.media-breakpoint-only(md) {
    .site-header-push {
        height: 68px;
    }

    .site-header-mobile {
        padding-bottom: 12px;
    }

    .site-header-btn {
        display: inline-block;
    }
}

@include mixins.media-breakpoint-up(md) {
    .site-header-logo,
    .site-header-logo svg {
        @include mixins.size(250px, 45px);
    }
}

@include mixins.media-breakpoint-up(lg) {
    .site-header-mobile {
        padding: 0;
        width: auto;
        margin-right: auto;
    }

    .site-header-menu-toggle {
        display: none;
    }
}
