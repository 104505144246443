@use "../global/variables";
@use "../mixins/breakpoints";

//
// Pagination component
//
.pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: variables.$spacer-3;
}

.pagination-item + .pagination-item {
    margin-left: 25px;
}

.pagination-item svg {
    display: block;
}

.pagination-link,
.pagination-link:hover {
    color: variables.$body-color;
    text-decoration: none;
}

.pagination-link.is-active {
    font-weight: variables.$font-weight-medium;
}

@include breakpoints.media-breakpoint-up(lg) {
    .pagination {
        margin-top: variables.$spacer-4;
    }

    .pagination-item + .pagination-item {
        margin-left: 50px;
    }
}
