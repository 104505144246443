@use "../global/variables";

@font-face {
    font-family: "Gotham-A";
    src: url("/assets/fonts/gotham_a_400_normal.eot");
    src: url("/assets/fonts/gotham_a_400_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_400_normal.woff2") format("woff2"), url("/assets/fonts/gotham_a_400_normal.woff") format("woff"), url("/assets/fonts/gotham_a_400_normal.ttf") format("truetype"), url("/assets/fonts/gotham_a_400_normal.svg#gotham_a") format("svg");
    font-weight: variables.$font-weight-normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-A";
    src: url("/assets/fonts/gotham_a_400_italic.eot");
    src: url("/assets/fonts/gotham_a_400_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_400_italic.woff2") format("woff2"), url("/assets/fonts/gotham_a_400_italic.woff") format("woff"), url("/assets/fonts/gotham_a_400_italic.ttf") format("truetype"), url("/assets/fonts/gotham_a_400_italic.svg#gotham_a") format("svg");
    font-weight: variables.$font-weight-normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-A";
    src: url("/assets/fonts/gotham_a_500_normal.eot");
    src: url("/assets/fonts/gotham_a_500_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_500_normal.woff2") format("woff2"), url("/assets/fonts/gotham_a_500_normal.woff") format("woff"), url("/assets/fonts/gotham_a_500_normal.ttf") format("truetype"), url("/assets/fonts/gotham_a_500_normal.svg#gotham_a") format("svg");
    font-weight: variables.$font-weight-medium;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-A";
    src: url("/assets/fonts/gotham_a_500_italic.eot");
    src: url("/assets/fonts/gotham_a_500_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_500_italic.woff2") format("woff2"), url("/assets/fonts/gotham_a_500_italic.woff") format("woff"), url("/assets/fonts/gotham_a_500_italic.ttf") format("truetype"), url("/assets/fonts/gotham_a_500_italic.svg#gotham_a") format("svg");
    font-weight: variables.$font-weight-medium;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-A";
    src: url("/assets/fonts/gotham_a_700_normal.eot");
    src: url("/assets/fonts/gotham_a_700_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_700_normal.woff2") format("woff2"), url("/assets/fonts/gotham_a_700_normal.woff") format("woff"), url("/assets/fonts/gotham_a_700_normal.ttf") format("truetype"), url("/assets/fonts/gotham_a_700_normal.svg#gotham_a") format("svg");
    font-weight: variables.$font-weight-bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-A";
    src: url("/assets/fonts/gotham_a_700_italic.eot");
    src: url("/assets/fonts/gotham_a_700_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_a_700_italic.woff2") format("woff2"), url("/assets/fonts/gotham_a_700_italic.woff") format("woff"), url("/assets/fonts/gotham_a_700_italic.ttf") format("truetype"), url("/assets/fonts/gotham_a_700_italic.svg#gotham_a") format("svg");
    font-weight: variables.$font-weight-bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-B";
    src: url("/assets/fonts/gotham_b_400_normal.eot");
    src: url("/assets/fonts/gotham_b_400_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_400_normal.woff2") format("woff2"), url("/assets/fonts/gotham_b_400_normal.woff") format("woff"), url("/assets/fonts/gotham_b_400_normal.ttf") format("truetype"), url("/assets/fonts/gotham_b_400_normal.svg#gotham_b") format("svg");
    font-weight: variables.$font-weight-normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-B";
    src: url("/assets/fonts/gotham_b_400_italic.eot");
    src: url("/assets/fonts/gotham_b_400_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_400_italic.woff2") format("woff2"), url("/assets/fonts/gotham_b_400_italic.woff") format("woff"), url("/assets/fonts/gotham_b_400_italic.ttf") format("truetype"), url("/assets/fonts/gotham_b_400_italic.svg#gotham_b") format("svg");
    font-weight: variables.$font-weight-normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-B";
    src: url("/assets/fonts/gotham_b_500_normal.eot");
    src: url("/assets/fonts/gotham_b_500_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_500_normal.woff2") format("woff2"), url("/assets/fonts/gotham_b_500_normal.woff") format("woff"), url("/assets/fonts/gotham_b_500_normal.ttf") format("truetype"), url("/assets/fonts/gotham_b_500_normal.svg#gotham_b") format("svg");
    font-weight: variables.$font-weight-medium;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-B";
    src: url("/assets/fonts/gotham_b_500_italic.eot");
    src: url("/assets/fonts/gotham_b_500_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_500_italic.woff2") format("woff2"), url("/assets/fonts/gotham_b_500_italic.woff") format("woff"), url("/assets/fonts/gotham_b_500_italic.ttf") format("truetype"), url("/assets/fonts/gotham_b_500_italic.svg#gotham_b") format("svg");
    font-weight: variables.$font-weight-medium;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-B";
    src: url("/assets/fonts/gotham_b_700_normal.eot");
    src: url("/assets/fonts/gotham_b_700_normal.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_700_normal.woff2") format("woff2"), url("/assets/fonts/gotham_b_700_normal.woff") format("woff"), url("/assets/fonts/gotham_b_700_normal.ttf") format("truetype"), url("/assets/fonts/gotham_b_700_normal.svg#gotham_b") format("svg");
    font-weight: variables.$font-weight-bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham-B";
    src: url("/assets/fonts/gotham_b_700_italic.eot");
    src: url("/assets/fonts/gotham_b_700_italic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/gotham_b_700_italic.woff2") format("woff2"), url("/assets/fonts/gotham_b_700_italic.woff") format("woff"), url("/assets/fonts/gotham_b_700_italic.ttf") format("truetype"), url("/assets/fonts/gotham_b_700_italic.svg#gotham_b") format("svg");
    font-weight: variables.$font-weight-bold;
    font-style: italic;
    font-display: swap;
}
