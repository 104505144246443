@use "../global/variables";
@use "../mixins";
//
// Form groups
//

.form-group {
    margin-bottom: 20px;
}

.form-group.has-errors .form-control {
    border-color: variables.$red;
}
.form-label {
    display: block;
    font-size: 0.9375rem;
    margin-bottom: 8px;
}

.form-button {
    margin-top: variables.$spacer-2;
}

.form-button .btn {
    width: 100%;

    @include mixins.media-breakpoint-up(md) {
        border-radius: 25px;
        padding-top: 14px;
        padding-bottom: 14px;
        width: auto;
        min-width: 275px;
    }
}

.form-error {
    color: variables.$red;
    display: block;
    font-size: 0.9375rem;
    margin-top: 8px;
}

.form-error {
    color: variables.$red;
    display: block;
    font-size: 0.9375rem;
    margin-top: 8px;
}

ul.form-error {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.form-error li {
    margin-top: 8px;
}

//
// Form controls
//

.form-control {
    appearance: none;
    background-clip: padding-box;
    background-color: variables.$white;
    border-radius: 25px;
    border: 1px solid variables.$gray-500;
    color: variables.$body-color;
    display: block;
    font-size: 0.9375rem;
    height: 50px;
    line-height: 20px;
    padding: 14px 18px;
    transition-duration: 175ms;
    transition-property: box-shadow, border-color;
    transition-timing-function: ease-in;
    width: 100%;

    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
        background-color: variables.$white;
        border-color: variables.$brand;
        box-shadow: 0 0 0 4px rgba(variables.$brand, 0.25);
        color: variables.$body-color;
        outline: 0;
    }

    // Placeholder
    &::placeholder {
        color: #b9b9b9;
        opacity: 1;
    }

    // Disabled and read-only inputs
    &:disabled,
    &[readonly] {
        background-color: variables.$white;
        opacity: 1;
    }
}

.form-control[type="file"] {
    overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
}

//
// Select boxes
//

select.form-control {
    background: #fefbf0 mixins.inline-svg('icon-chevron-down', variables.$brand) no-repeat center right 20px / 20px;
    border-color: variables.$brand;
    padding-right: 47px;
}

select.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 variables.$body-color;
}

select.form-control[multiple],
select.form-control[size]:not([size="1"]) {
    height: auto;
}

//
// Textareas
//

textarea.form-control {
    //@include relative();
    height: auto;
    min-height: 160px;
}

//textarea.form-control::after
//    //@include absolute(bottom right)
//    @include size(30px)
//    background: red
//    //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M2.511,28.95L28.96,2.477l.011,26.46-26.461,.012Z' fill='%23d2d1d1' /%3E%3Cpath d='M28.972,2.477L2.511,28.95l26.472-.012-.011-26.46m1.016-2.477l.013,29.986-30,.014L29.987,0Z' fill='%23707070' /%3E%3C/svg%3E%0A")
//    content: ""
//    display: block

//
// Checkbox group
//

.form-checkbox-group {
    display: grid;
}

.form-checkbox {
    background: variables.$gray-100;
    display: flex;
    align-items: center;
    padding: 1px;
}

.form-checkbox-input {
    @include mixins.absolute(left);
    opacity: 0;
    z-index: -1;
}

.form-checkbox-option {
    align-items: center;
    background: variables.$white;
    color: variables.$gray-600;
    display: flex;
    flex-grow: 1;
    height: 80px;
    padding: 16px 24px;
    transition: background-color 175ms ease-in;
}

.form-checkbox-option:hover {
    background: rgba(variables.$white, 0.5);
}

.form-checkbox-indicator {
    @include mixins.size(28px);
    appearance: none;
    background: variables.$white;
    border: 1px solid variables.$brand;
    border-radius: 14px;
    flex: 0 0 28px;
}

.form-checkbox-label {
    font-size: 0.9375rem;
    margin-left: 38px;
}

.form-checkbox-input:checked ~ .form-checkbox-option {
    background: rgba(variables.$white, 0);

    .form-checkbox-indicator {
        background: variables.$brand mixins.inline-svg('icon-tick', variables.$gray-700) no-repeat center center / 13px;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .form-checkbox-group {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

//
// Horizontal form
//

@include mixins.media-breakpoint-up(md) {
    .form-horizontal {
        .form-group,
        .form-button {
            display: grid;
            grid-template-columns: repeat(8, minmax(0, 1fr));
            column-gap: 25px;
        }

        .form-label {
            grid-column: auto / span 2;
            margin: 15px 0 0;
        }

        .form-group > :nth-child(2) {
            grid-column: auto / span 6;
        }

        .form-button .btn {
            grid-column-start: 3;
        }
    }
}

@include mixins.media-breakpoint-up(lg) {
    .form-horizontal {
        .form-group,
        .form-button {
            grid-template-columns: repeat(12, minmax(0, 1fr));
            column-gap: 16px;
        }

        .form-label {
            grid-column: auto / span 3;
        }

        .form-group > :nth-child(2) {
            grid-column: auto / span 7;
        }

        .form-button .btn {
            grid-column-start: 4;
        }
    }
}

@include mixins.media-breakpoint-up(xl) {
    .form-horizontal {
        .form-group,
        .form-button {
            column-gap: 50px;
        }

        .form-label {
            grid-column: 3 / span 2;
        }

        .form-group > :nth-child(2) {
            grid-column: auto / span 5;
        }

        .form-button .btn {
            grid-column-start: 5;
        }
    }
}
