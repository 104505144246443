@use "../global/variables";
@use "../mixins";

//
// Contact section
//
.contact-checkbox-group {
    background: variables.$gray-100;
    padding: 1px;
    gap: 1px;
    display: grid;
}

.contact-checkbox {
    align-items: center;
    background: variables.$white;
    color: variables.$gray-600;
    display: flex;
    padding: 32px 24px;
    transition: background-color 175ms ease-in;
}

.contact-checkbox:hover {
    background: rgba(variables.$white, 0.5);
}

.contact-checkbox.is-checked {
    background: rgba(variables.$white, 0);
}

.contact-checkbox-input {
    @include mixins.size(28px);
    appearance: none;
    background: variables.$white;
    border: 1px solid variables.$brand;
    border-radius: 14px;
    flex: 0 0 28px;
}

.contact-checkbox-input:checked {
    background: variables.$brand mixins.inline-svg('icon-tick', variables.$gray-700) no-repeat center center / 13px;
}

.contact-checkbox-label {
    margin-left: 38px;
}

.contact-checkbox-label-title {
    font-size: 0.9375rem;
    font-weight: variables.$font-weight-medium;
}

.contact-checkbox-label-description {
    font-size: 0.8125rem;
}

@include mixins.media-breakpoint-up(lg) {
    .contact-checkbox-group {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
