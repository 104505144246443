@use "../global/variables";
@use "../mixins";

//
// Accordion component
//
.accordion-panel {
    border-bottom: 2px solid variables.$gray-200;
}

.accordion-panel-header {
    cursor: pointer;
    outline: 0;
    padding: 15px 28px 15px 0;
    position: relative;
}

.accordion-panel-header::after {
    @include mixins.absolute(top 11px right);
    @include mixins.size(28px);
    background: mixins.inline-svg('icon-chevron-down', variables.$brand) no-repeat center center / 28px;
    content: "";
    display: block;
    transition: transform 350ms ease-in-out;
}

.accordion-panel.active .accordion-panel-header::after {
    transform: rotate(180deg);
}

.accordion-panel-title {
    font-size: inherit;
    line-height: inherit;
    font-weight: variables.$font-weight-medium;
    margin-bottom: 0;
}

.accordion-panel-body {
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height 350ms ease-in-out;
}

.accordion-panel.active .accordion-panel-body {
    height: auto;
}

.accordion-panel-body-inner {
    padding: 0 0 25px;
}

.accordion-panel-content > :last-child {
    margin-bottom: 0;
}

.accordion-panel-image {
    margin-top: 25px;
}

.accordion-panel-image img,
.accordion-panel-image video {
    @include mixins.img-fluid();
}

@include mixins.media-breakpoint-up(lg) {
    .accordion-panel-header {
        padding: 25px 0;
    }

    .accordion-panel-header::after {
        @include mixins.position(top 23px);
    }

    .accordion-panel-body-inner {
        padding-bottom: 36px;
    }
}
