@use "../global/variables";
@use "../mixins";

//
// Skip nav component
//
.skipnav {
    text-align: left;
}

.skipnav a {
    @include mixins.absolute(left -9999px);
    @include mixins.size(1px);
    overflow: hidden;
    display: inline-block;
}

.skipnav a:focus,
.skipnav a:active {
    @include mixins.position(left 0);
    @include mixins.size(auto);
    background: variables.$white;
    overflow: visible;
    padding: 5px;
    text-decoration: underline;
    z-index: 10000;
}
