@use "../global/variables";
@use "../mixins";

//
// Product page
//

.product-page-padding {
    padding-top: variables.$spacer-1 !important;
}

.product {
  display: grid;
  column-gap: 25px;
  row-gap: variables.$spacer-2;
}

.product-intro {
  display: flex;
  flex-direction: column;
  margin-bottom: variables.$spacer-2;
  @include mixins.media-breakpoint-up(md) {
    flex-direction: row;
    gap: 25px;
  }
  @include mixins.media-breakpoint-up(lg) {
    margin-bottom: variables.$spacer-4;
  }
}

.product-intro .product-gallery {
  margin-bottom: variables.$spacer-2;
  @include mixins.media-breakpoint-up(md) {
    width: 50%;
    margin-bottom: 0;
  }
}
.product-intro .page-title {
  font-size: 2.5rem;
  margin-bottom: variables.$spacer-1
}

.product-intro img {
  margin-bottom: variables.$spacer-1;
}

.product-heading {
  border-bottom: 1px solid variables.$gray-600;
  padding-bottom: 10px;
}

.product-intro-content {
  margin-bottom: variables.$spacer-1;
  @include mixins.media-breakpoint-up(md) {
    width: 50%;
  }

  .page-title {
    text-align: left;
  }
}

@include mixins.media-breakpoint-up(md) {
  .product {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .product-wide {
    grid-column: auto / span 2;
  }

  .product .page-title {
    text-align: left;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .product {
    column-gap: 50px;
    row-gap: variables.$spacer-4;
  }

  .product-intro .page-title {
    margin-bottom: variables.$spacer-2;
  }

  .product-heading {
    margin-bottom: variables.$spacer-2;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .product {
    column-gap: 3 * variables.$spacer-3;
  }
}

//
// Configurations
//

.product-configurations {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: variables.$grid-gutter-width;
}

.product-configurations figcaption {
  text-align: center;
}

@include mixins.media-breakpoint-up(md) {
  .product-configurations {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@include mixins.media-breakpoint-up(lg) {
  .product-configurations {
    column-gap: variables.$spacer-2;
    row-gap: variables.$spacer-4;
  }
}

//
// Installations
//

.product-installations {
  display: grid;
  gap: variables.$grid-gutter-width;
}

.product-installations figcaption {
  text-align: center;
}

@include mixins.media-breakpoint-up(md) {
  .product-installations {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@include mixins.media-breakpoint-up(lg) {
  .product-installations {
    column-gap: variables.$spacer-2;
    row-gap: variables.$spacer-4;
  }
}

//
// Dimensions
//

.product-dimensions {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: variables.$spacer-1;
}

.product-dimensions-item {
  flex: 1;
}

.product-dimensions figcaption {
  margin-top: 0;
  margin-bottom: 26px;
}

@include mixins.media-breakpoint-up(md) {
  .product-dimensions {
    display: flex;
  }
}

//
// Colour range
//

.product-colours {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  text-align: center;
}

.product-colours-item img {
  @include mixins.img-fluid();
  max-width: 70px;
}

@include mixins.media-breakpoint-up(lg) {
  .product-colours {
    gap: 35px;
  }

  .product-colours-item img {
    max-width: 85px;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .product-colours-item img {
    max-width: 105px;
  }
}

//
// Controls
//

.product-controls {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  text-align: center;
}

.product-controls-item svg {
  height: 85px;
}

@include mixins.media-breakpoint-up(lg) {
  .product-controls {
    gap: 35px;
  }

  .product-controls-item svg {
    height: 110px;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .product-controls-item svg {
    max-width: 140px;
  }
}

//
// Explore more
//

.product-explore {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 25px;
}

.product-explore-item-image-wrapper {
  margin: 0 auto 15px;
  max-width: 160px;
}

.product-explore-item-image {
  @include mixins.relative();
  border-radius: 100%;
  background: variables.$white;
  overflow: hidden;
  display: flex;
  width: 100%;
  padding-bottom: 100%;
}

.product-explore-item-image img {
  @include mixins.absolute(top 50% left 50%);
  @include mixins.max-size(75%);
  transform: translate(-50%, -50%);
  margin: auto;
}

.product-explore-item-title {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: variables.$spacer-1;
}

.product-explore-item-excerpt {
  font-size: 14px;
  line-height: 1.75;
}

@include mixins.media-breakpoint-up(md) {
  .product-explore {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@include mixins.media-breakpoint-up(lg) {
  .product-explore {
    gap: 50px;
  }

  .product-explore-item-image::before {
    @include mixins.absolute(top 50% left 50%);
    @include mixins.size(42px);
    background: variables.$gray-900 mixins.inline-svg('icon-plus', variables.$brand) no-repeat center center / 18px;
    border-radius: 42px;
    content: "";
    display: block;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 350ms ease-in-out;
    z-index: 10;
  }

  .product-explore-item-image:hover::before {
    opacity: 1;
  }

  .product-explore-item-title {
    font-size: 16px;
    font-weight: variables.$font-weight-medium;
  }

  .product-explore-item-excerpt {
    font-size: 14px;
  }
}
