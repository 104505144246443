//
// Colors
//

$white: #fff !default;
$gray-100: #f8f8f9;
$gray-200: #e6e5e5;
$gray-300: #d7d5d5;
$gray-400: #cdcdcd;
$gray-500: #898989;
$gray-600: #707070;
$gray-700: #53555c;
$gray-800: #343a40 !default;
$gray-900: #2f3036;
$black: #000 !default;

$brand: #ffcd07;
$brand-dark: #e8bb06;
$brand-light: #f4e094;

$blue: #0d6efd !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $yellow !default;
$warning: $orange !default;
$danger: $red !default;

//
// Body
//

$body-bg: $white;
$body-color: $gray-700;

$text-muted: $gray-600 !default;

//
// Links
//

$link-color: $brand;
$link-decoration: none;

$link-hover-color: $brand-dark;
$link-hover-decoration: underline;

//
// Grid
//

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-breakpoints: (xs: 0, sm: 393px, md: 768px, lg: 1200px, xl: 1980px);

$container-max-width: 1654px;
$container-gutter-sm: 20px;
$container-gutter-lg: 85px;

//
// Components
//

$border-color: $gray-300 !default;
$border-radius: 4px !default;
$border-width: 1px !default;

$embed-responsive-aspect-ratios: ((21 9), (16 9), (4 3), (1 1)) !default;

//
// Typography
//

$font-family-sans-serif: Gotham-A, Gotham-B, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif: Georgia, "Times New Roman", Times, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-weight-lighter: lighter !default;
//$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder !default;

$font-size-base: 1rem !default;
$line-height-base: 1.5 !default;
$font-weight-base: $font-weight-normal !default;

$small-font-size: 80% !default;

//
// Spacing
//

$spacer-1: 36px;
$spacer-2: 68px;
$spacer-3: 104px;
$spacer-4: 136px;

$headings-margin-bottom: 1rem !default;
$paragraph-margin-bottom: 1rem !default;

$form-group-margin-bottom: 1rem !default;
$label-margin-bottom: 0.5rem !default;
$form-text-margin-top: 0.25rem !default;

//
// Mobile menu animation
//

$mobile-menu-item-count: 8;
$mobile-menu-item-delay: 85ms;
$mobile-menu-item-duration: 275ms;
$mobile-menu-item-duration-total: ($mobile-menu-item-count * $mobile-menu-item-delay) + $mobile-menu-item-duration;

$mobile-menu-bg-delay-in: 0ms;
$mobile-menu-bg-delay-out: 0.65 * $mobile-menu-item-duration-total;
$mobile-menu-bg-duration: 750ms;

$mobile-menu-delay-in: 0ms;
$mobile-menu-delay-out: $mobile-menu-bg-delay-out + $mobile-menu-bg-duration;
