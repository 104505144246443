@use "../global/variables";
@use "../mixins";

//
// Products page
//
.products-title {
    margin-bottom: variables.$spacer-1;
}

.products-hero {
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    margin-bottom: variables.$spacer-2;
    border-radius: 5px;
    @include mixins.media-breakpoint-up(md) {
        flex-direction: row;
        align-items: stretch;
        grid-template-columns: repeat(2, 1fr);
    }
    @include mixins.media-breakpoint-up(lg) {
        margin-top: -50px;
        border-radius: 10px;
        margin-bottom: variables.$spacer-3;
    }
}

.products-hero-content {
    align-self: center;
    padding: variables.$spacer-2 variables.$spacer-1;
}

.products-grid-heading {
    text-align: center;
}

.products-grid {
    column-gap: 25px;
    display: grid;
    margin-bottom: variables.$spacer-3;
    row-gap: variables.$spacer-1;
}

.products-hero-item-image,
.products-grid-item-image {
    cursor: pointer;
    display: block;
    margin-bottom: 25px;
}

.products-hero-item-title,
.products-grid-item-title {
    font-size: 1.5rem;
    margin-bottom: 16px;
}

.products-hero-item-excerpt > :last-child,
.products-grid-item-excerpt > :last-child {
    margin-bottom: 0;
}

.products-hero-item-title {
    font-weight: variables.$font-weight-normal;
}

.products-hero-item-excerpt {
    margin-bottom: variables.$spacer-1;
}

.products-grid-item-title {
    font-weight: variables.$font-weight-medium;
}

.products-grid-item-excerpt {
    margin-bottom: 16px;
}

.products-hero-item-image {
    margin-bottom: 0;
}

.products-hero-item-image img {
    border-radius: 5px 5px 0 0;
    @include mixins.media-breakpoint-up(md) {
        width: 532px;
        border-radius: 10px 0 0 10px;
    }
}

.products-grid-hero-more {
    padding-top: 9px;
    padding-bottom: 9px;
    min-width: 160px;
}

.products-grid-item-more {
    padding-top: 9px;
    padding-bottom: 9px;
    min-width: 160px;
}

@include mixins.media-breakpoint-up(md) {
    .products-hero-item-image img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
    }

    .products-grid-heading {
        margin-bottom: variables.$spacer-2;
    }

    .products-grid {
        grid-template-columns: repeat(2, 1fr);
        row-gap: variables.$spacer-2;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .products-grid {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        margin-bottom: variables.$spacer-4;
    }

    .products-hero-item-image {
        margin-bottom: 0;
        max-width: 600px;
    }

    .products-hero-item-image,
    .products-grid-item-image {
        @include mixins.relative();
    }

    .products-grid-item-image {
        margin-bottom: 50px;
    }
    .products-hero-item-image::before,
    .products-grid-item-image::before {
        @include mixins.absolute(top 50% left 50%);
        @include mixins.size(85px);
        background: variables.$gray-900 mixins.inline-svg('icon-plus', variables.$brand) no-repeat center center / 36px;
        border-radius: 85px;
        content: "";
        display: block;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 350ms ease-in-out;
    }

    .products-hero-item-image:hover::before,
    .products-grid-item-image:hover::before {
        opacity: 1;
    }

    .products-title {
        margin-bottom: 24px;
    }

    .products-hero-item-title {
        margin-bottom: variables.$spacer-2;
    }
}

//
// Learn more
//

.products-learn {
    display: block;
    background: variables.$brand;
}

.products-learn:hover {
    text-decoration: none;
}

.products-learn-text {
    color: variables.$white;
    font-size: 24px;
    padding: 30px 15px;
    text-align: center;
}


@include mixins.media-breakpoint-up(md) {
    .products-learn {
        align-items: center;
        display: flex;
        margin: 0 auto;
        max-width: 1120px;
    }

    .products-learn-text {
        flex-grow: 1;
        font-size: 30px;
        order: 1;
    }

    .products-learn-image {
        order: 2;
        max-width: 330px;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .products-learn-text {
        font-size: 40px;
    }

    .products-learn-image {
        max-width: 365px;
    }
}

@include mixins.media-breakpoint-up(xl) {
    .products-learn-image {
        max-width: 375px;
    }
}
