@use "../global/variables";
@use "../mixins";

//
// Support section
//
.support-guide {
    display: grid;
    row-gap: variables.$spacer-2;
    counter-reset: section;
}

.support-guide img {
    @include mixins.img-fluid();
}

.support-guide-item {
    counter-increment: section;
}

.support-guide-item-content {
    margin-bottom: 20px;
}

.support-guide-item-content strong {
    font-weight: variables.$font-weight-medium;
}

.support-guide-item-content > :first-child::before {
    content: counter(section) ". ";
    font-weight: variables.$font-weight-medium;
}

.support-guide-item-content > :last-child {
    margin-bottom: 0;
}

@include mixins.media-breakpoint-up(md) {
    .support-guide-item {
        display: grid;
        column-gap: variables.$grid-gutter-width;
        grid-template-columns: repeat(12, 1fr);
    }

    .support-guide-item-img,
    .support-guide-item-content {
        grid-column: auto / span 6;
    }

    .support-guide-item-content {
        margin-bottom: 0;
    }

    .support-guide-item:nth-child(even) .support-guide-item-img {
        order: 1;
    }

    .support-guide-item:nth-child(even) .support-guide-item-content {
        order: 2;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .support-guide {
        row-gap: variables.$spacer-4;
    }

    .support-guide-item-content {
        grid-column: auto / span 5;
    }

    .support-guide-item:nth-child(even) .support-guide-item-content {
        grid-column-start: 8;
    }

    .support-guide-item:nth-child(odd) .support-guide-item-img {
        grid-column-start: 7;
    }
}


.support-callout {
    @include mixins.relative();
    display: block;
}

.support-callout:hover {
    text-decoration: none;
}

.support-callout-text {
    background: variables.$gray-200;
    color: variables.$body-color;
    font-size: 24px;
}

.support-callout-text-inner {
    padding: 30px 15px;
    text-align: center;
}

@include mixins.media-breakpoint-down(sm) {
    .support-callout {
        padding-top: 16%;
    }

    .support-callout-text {
        padding-top: 46%;
    }

    .support-callout-image {
        @include mixins.absolute(top left);
        width: 100%;
    }
}

@include mixins.media-breakpoint-up(md) {
    .support-callout {
        margin: 0 auto;
        max-width: 1120px;
    }

    .support-callout-text {
        font-size: 30px;
        padding-right: 330px;
    }

    .support-callout-text-inner {
        padding-left: 30px;
        padding-right: 0;
        text-align: center;
    }

    .support-callout-image {
        @include mixins.absolute(bottom right);
        max-width: 330px;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .support-callout-text {
        font-size: 40px;
        padding-right: 365px;
    }

    .support-callout-image {
        max-width: 365px;
    }
}

@include mixins.media-breakpoint-up(xl) {
    .support-callout-text {
        padding-right: 375px;
    }

    .support-callout-image {
        max-width: 375px;
    }
}
