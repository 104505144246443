@use "../global/variables";
@use "../mixins";

//
// Modal component
//
.modal {
    @include mixins.relative();
    z-index: 50;
}

.modal-shade,
.modal-scroller {
    @include mixins.fixed(top left bottom right);
}

.modal-shade {
    background: rgba(variables.$black, 0.5);
}

.modal-scroller {
    overflow-y: auto;
}

.modal-inner {
    align-items: center;
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding: 25px 20px;
}

.modal-panel {
    @include mixins.relative();
    background: variables.$white;
    padding: 20px 15px;
    width: 100%;
    max-width: 1080px;
}

.modal-close,
.modal-prev,
.modal-next {
    @include mixins.size(55px);
    align-items: center;
    border: 0;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    padding: 0;
    transition: 175ms ease-in;
    transition-property: background-color, color;

    &:focus {
        outline: 0;
    }
}

.modal-close {
    @include mixins.absolute(top variables.$spacer-1 right variables.$spacer-1);
    background: rgba(variables.$gray-200, 0);
    color: variables.$brand;

    &:hover {
        background: rgba(variables.$gray-200, 0.35);
        color: variables.$gray-700;
    }
}

.modal-close svg {
    @include mixins.size(25px);
}

.modal-prev,
.modal-next {
    @include mixins.absolute(top 50%);
    background: rgba(variables.$gray-200, 0.35);
    margin-top: -28px;

    &:hover {
        background: variables.$gray-200;
    }
}

.modal-prev {
    @include mixins.position(left variables.$spacer-1);
}

.modal-next {
    @include mixins.position(right variables.$spacer-1);
}

.modal-prev svg,
.modal-next svg {
    @include mixins.size(18px);
    display: block;
}

.modal-prev svg {
    margin-left: -4px;
}

.modal-next svg {
    margin-right: -4px;
}

@include mixins.media-breakpoint-up(md) {
    .modal-panel {
        padding: 127px variables.$spacer-1 variables.$spacer-1;
    }

    .modal-title {
        margin-bottom: variables.$spacer-1;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .modal-panel {
        padding: 127px variables.$spacer-2 variables.$spacer-2;
    }
}

//
// Modal transitions
//

.modal-enter-active,
.modal-shade-enter-active {
    transition: 375ms ease-out;
}

.modal-leave-active,
.modal-shade-leave-active {
    transition: 275ms ease-in;
}

.modal-enter-from,
.modal-leave-to,
.modal-shade-enter-from,
.modal-shade-leave-to {
    opacity: 0;
}

.modal-enter-from {
    transform: translate(0, 30px);
}

.modal-leave-to {
    transform: translate(0, -30px);
}

.modal-enter-to,
.modal-leave-from,
.modal-shade-enter-to,
.modal-shade-leave-from {
    opacity: 1;
}
