@use "../global/variables";
@use "../mixins";
@use "sass:color";

//
// Store locator section
//
#locator {
  height: 100vh;
  overflow: hidden;

  .site-footer {
    display: none;
  }
}

.locator-results,
#locator .locator,
#locator .site-main {
  height: calc(100vh - 50px);
}

.locator-search-fields {
  display: flex;
  flex-direction: column;
  margin-bottom: variables.$spacer-1;
  row-gap: 16px;
}

.locator-search-btn .btn {
  width: 100%;
}

.locator-results {
  display: flex;
  flex-direction: column;
}

.locator-results-fields {
  column-gap: 16px;
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  margin-bottom: 16px;
}

.locator-fields-location,
.locator-fields-distance,
.locator-fields-retailer {
  @include mixins.relative();
}

.locator-fields-error {
  @include mixins.absolute(bottom 100% left 50%);
  background-color: color.adjust(variables.$danger, $lightness: 43%);
  border: 1px solid variables.$danger;
  border-radius: 15px;
  color: color.adjust(variables.$danger, $lightness: -13%);
  font-size: 0.9375rem;
  line-height: 20px;
  padding: 4px 14px;
  transform: translate(-50%, -10px);
  white-space: nowrap;
}

.locator-fields-error::before,
.locator-fields-error::after {
  @include mixins.absolute(top 100% left 50%);
  @include mixins.size(0);
  border: 7px solid transparent;
  content: "";
  display: block;
}

.locator-fields-error::before {
  border-top-color: variables.$danger;
  transform: translate(-50%, 0);
}

.locator-fields-error::after {
  border-top-color: color.adjust(variables.$danger, $lightness: 43%);
  transform: translate(-50%, -1px);
}

.locator-fields-location-results {
  @include mixins.absolute();
  background: variables.$white;
  border: 1px solid variables.$brand;
  border-radius: 25px;
  font-size: 0.9375rem;
  margin-top: 7px;
  overflow: hidden;
  width: 100%;
  z-index: 10;
}

.locator-fields-location-results-item {
  padding: 14px 18px;
  line-height: 20px;
}

.locator-results-fields-toggle {
  @include mixins.size(50px);
  flex-shrink: 0;
  display: flex;
  padding: 0;
}

.locator-results-fields-toggle svg {
  margin: auto;
  width: 20px;
}

.locator-results-combined {
  display: grid;
  grid-template-columns: 450px auto;
}

.locator-results-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include mixins.media-breakpoint-up(md) {
    padding-left: variables.$grid-gutter-width;
    padding-right: 16px;
  }
}

.locator-results-list-item {
  display: flex;
  font-size: 0.9375rem;
  padding: 14px 0 20px;

  @include mixins.media-breakpoint-up(md) {
    padding: 14px 24px 20px 5px;
  }

  &.is-selected {
    background: rgba(variables.$gray-900, 0.04);
  }

  &.is-floating {
    background: none;
    padding: 5px 10px;
  }
}

.locator-results-list-item-number {
  @include mixins.size(28px);
  align-items: center;
  background: variables.$gray-600;
  border-radius: 14px;
  color: variables.$white;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 18px;

  .is-selected & {
    background: variables.$brand;
    color: variables.$gray-900;
  }

  .is-floating & {
    display: none;
  }
}

.locator-results-list-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.locator-results-list-item-main {
  display: flex;
}

.locator-results-list-item-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.locator-results-list-item-title {
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 5px;
}

.locator-results-list-item-address {
  color: variables.$gray-600;
}

.locator-results-list-item-distance {
  color: variables.$gray-600;
  line-height: 1.8;
  margin-left: 15px;
}

.locator-results-list-item-footer {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.locator-results-list-item-contact {
  display: flex;
  column-gap: 10px;
}

.locator-results-list-item-email,
.locator-results-list-item-website {
  font-size: 15px;
  line-height: 20px;
  padding: 4px 12px;
}

.locator-results-list-item-email {
  background-color: variables.$brand;
  border-color: variables.$brand;
  color: variables.$black;

  &:hover,
  &:focus {
    background-color: variables.$brand-dark;
    border-color: variables.$brand-dark;
    color: variables.$black;
  }
}

.locator-results-list-item-website {
  background: variables.$gray-300;
  color: variables.$gray-900;
  font-weight: normal;
}

.locator-results-list-item-website:hover {
  background: variables.$gray-400;
  color: variables.$black;
}

.locator-results-list-item + .locator-results-list-item {
  border-top: 1px solid rgba(variables.$gray-600, 0.3);
}

.locator-results-list-item-types {
  display: flex;
  margin-left: auto;
}

.locator-results-list-item-types > div + div {
  margin-left: 15px;
}

.locator-results-list-item-types .locator-icon {
  height: 18px;
}

.locator-results-list-item-types .locator-icon-bg {
  opacity: 0;
}

.locator-results-list-item-types .locator-icon:hover .locator-icon-bg {
  opacity: 1;
}

.locator-results-map {
  flex: 1 1 auto;
  height: 100%;
}

.locator-tooltip {
  @include mixins.relative();
}

.locator-tooltip-panel {
  @include mixins.absolute(top right 50%);
  background: variables.$brand;
  color: variables.$gray-900;
  font-size: 0.8125rem;
  opacity: 0;
  padding: 5px 15px;
  transform: translate(0, calc(-100% - 10px));
  white-space: nowrap;
  z-index: 100;

  .locator-tooltip:hover & {
    opacity: 1;
  }
}

.locator-tooltip-panel::before {
  @include mixins.absolute(top 100% right);
  @include mixins.size(0);
  border: 5px solid transparent;
  border-top-color: variables.$brand;
  border-right-color: variables.$brand;
  content: "";
  display: block;
}

@include mixins.media-breakpoint-down(sm) {
  .locator .page-title,
  .locator .page-intro {
    margin-bottom: 50px;
  }

  .locator-results-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
    grid-template-rows: 1fr 1fr;
    row-gap: 14px;

    .locator-fields-location {
      grid-column: auto / span 3;
    }
  }
}

@include mixins.media-breakpoint-up(md) {
  .locator-results,
  #locator .locator,
  #locator .site-main {
    height: calc(100vh - 68px);
  }

  .locator-search-fields {
    column-gap: 25px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 0 auto variables.$spacer-2;
    max-width: 570px;

    .locator-fields-location {
      grid-column: auto / span 2;
    }
  }

  .locator-search-btn {
    margin-left: auto;
    margin-right: auto;
    max-width: 290px;
  }

  .locator-search-btn .btn {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .locator-results-combined {
    height: calc(100vh - #{68px + 80px});
  }

  .locator-results-fields {
    width: 100%;
    max-width: 1020px;

    .locator-fields-location {
      width: 40%;
    }

    .locator-fields-retailer,
    .locator-fields-distance {
      width: 30%;
    }
  }
}

@include mixins.media-breakpoint-up(lg) {
  .locator-results,
  #locator .locator,
  #locator .site-main {
    height: calc(100vh - 105px);
  }

  .locator-search-fields {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    max-width: 1120px;
  }

  .locator-results-combined {
    height: calc(100vh - #{105px + 80px});
  }

  .locator-fields-location-results-item.is-active {
    background: rgba(variables.$brand-light, 0.4);
    cursor: pointer;
  }
}

//
// Search callout
//

.locator-search-callout {
  background: variables.$gray-600;
  display: block;
  margin-top: variables.$spacer-3;
}

.locator-search-callout:hover {
  text-decoration: none;
}

.locator-search-callout-text {
  color: variables.$white;
  font-size: 24px;
  padding: 30px 15px;
  text-align: center;
}

.locator-search-callout-text strong {
  font-weight: variables.$font-weight-medium;
}

@include mixins.media-breakpoint-up(md) {
  .locator-search-callout {
    align-items: center;
    display: flex;
    margin: variables.$spacer-4 auto 0;
    max-width: 1120px;
  }

  .locator-search-callout-text {
    flex-grow: 1;
    font-size: 30px;
    order: 1;
  }

  .locator-search-callout-image {
    order: 2;
    max-width: 330px;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .locator-search-callout-text {
    font-size: 40px;
  }

  .locator-search-callout-image {
    max-width: 365px;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .locator-search-callout-image {
    max-width: 375px;
  }
}

//
// Email modal
//

.locator-modal .modal-close {
  @include mixins.position(top 10px right 10px);
}

.locator-modal .modal-panel {
  padding-top: 60px;
}

.locator-modal-body-content {
  margin-bottom: variables.$spacer-2;
}

.locator-modal-body-retailers {
  background-clip: padding-box;
  background: #fefbf0;
  border: 1px solid variables.$brand;
  border-radius: 25px;
  color: variables.$body-color;
}

.locator-modal-body-retailers-button {
  appearance: none;
  background: transparent mixins.inline-svg('icon-chevron-down', variables.$brand) no-repeat center right 20px / 20px;
  border: 0;
  color: variables.$body-color;
  display: block;
  font-size: 0.9375rem;
  height: 48px;
  line-height: 20px;
  padding: 14px 38px 14px 18px;
  text-align: left;
  transition-duration: 175ms;
  transition-property: box-shadow, border-color;
  transition-timing-function: ease-in;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.locator-modal-body-retailers-content {
  font-size: 0.9375rem;
  padding: 0 18px 14px;
}

.locator-checkbox-group {
  display: flex;
  flex-direction: column;
}

.locator-checkbox {
  align-items: center;
  color: variables.$gray-600;
  display: flex;
  padding: (0.5 * variables.$spacer-1) 4px;
}

.locator-checkbox-input {
  @include mixins.size(28px);
  appearance: none;
  background: transparent;
  border: 1px solid variables.$brand;
  border-radius: 14px;
  flex: 0 0 28px;
}

.locator-checkbox-input:checked {
  background: variables.$brand mixins.inline-svg('icon-tick', variables.$gray-700) no-repeat center center / 13px;
}

.locator-checkbox-label {
  margin-left: 22px;
}

@include mixins.media-breakpoint-up(md) {
  .locator-modal-body {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 25px;
  }

  .locator-modal-body-form {
    grid-column: auto / span 2;
    margin-bottom: 0;
    order: 1;
  }

  .locator-modal-body-content {
    order: 2;
  }

  .locator-modal-body-form .form-group,
  .locator-modal-body-form .form-button {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .locator-modal-body-form .form-control {
    grid-column: auto / span 3;
  }

  .locator-modal-body-form .locator-modal-body-offset {
    grid-column: 2 / span 3;
  }

  .locator-modal-body-form .form-label {
    margin: 15px 0 0;
  }

  .locator-modal-body-form .form-button {
    margin-top: variables.$spacer-2;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .locator-modal-body {
    column-gap: 50px;
  }
}
