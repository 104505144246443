@use "../mixins";

//
// Embed classes
//
.embed-responsive {
    @include mixins.relative();
    display: block;
    overflow: hidden;
    padding: 0;
    width: 100%;

    &::before {
        content: "";
        display: block;
    }

    embed,
    iframe,
    object,
    video,
    .embed-responsive-item {
        @include mixins.absolute(top left bottom);
        @include mixins.size(100%);
        border: 0;
    }
}

//@each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios
//    $embed-responsive-aspect-ratio-x: nth($embed-responsive-aspect-ratio, 1)
//    $embed-responsive-aspect-ratio-y: nth($embed-responsive-aspect-ratio, 2)
//    .embed-responsive-#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y}::before
//        padding-top: percentage($embed-responsive-aspect-ratio-y / $embed-responsive-aspect-ratio-x)
