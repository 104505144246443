@use "../global/variables";
@use "../mixins/breakpoints";

//
// Download component
//
.download-grid {
    display: grid;
}

.download {
    align-items: center;
    border-bottom: 2px solid variables.$gray-200;
    display: flex;
    padding: 0.5 * variables.$spacer-1 0;
}

.download-view,
.download-download {
    color: variables.$brand;
    transition: color 175ms ease-in;
}

.download-view:hover,
.download-download:hover {
    color: variables.$gray-700;

    .page-section-dark & {
        color: variables.$gray-200;
    }
}

.download-download {
    margin-left: 20px;
}

.download-title {
    margin-left: 40px;
}

@include breakpoints.media-breakpoint-down(sm) {
    .download-download {
        display: none;
    }
}

@include breakpoints.media-breakpoint-up(md) {
    .download-grid {
        column-gap: 25px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: variables.$spacer-1;
    }
}

@include breakpoints.media-breakpoint-up(lg) {
    .download-grid {
        column-gap: 50px;
    }
}
