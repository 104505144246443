@use "../global/variables";
@use "../mixins";
@use "../utilities/embed";
//
// Video player
//
.video {
  @extend .embed-responsive;
  background: no-repeat center center / cover;
  cursor: pointer;
  padding-bottom: 56.25%; //percentage(360 / 640)
}

.video::before {
  @include mixins.absolute(top 50% left 50%);
  @include mixins.size(80px);
  background: mixins.inline-svg('icon-play', variables.$white) no-repeat center center / contain;
  content: "";
  display: block;
  transform: translate(-50%, -50%);
}

.video.active::before {
  display: none;
}

@include mixins.media-breakpoint-up(md) {
  .video::before {
    @include mixins.size(110px);
  }
}
