@mixin size($width, $height: null) {
    @if ($height == null) {
        $height: $width;
    }
    height: $height;
    width: $width;
}

@mixin min-size($width, $height: null) {
    @if ($height == null) {
        $height: $width;
    }
    min-height: $height;
    min-width: $width;
}

@mixin max-size($width, $height: null) {
    @if ($height == null) {
        $height: $width;
    }
    max-height: $height;
    max-width: $width;
}
