@use "../global/variables";
@use "../mixins/breakpoints";

//
// Button styles
//
.btn {
    background-color: transparent;
    border: variables.$border-width solid transparent;
    border-radius: 25px;
    color: variables.$body-color;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: variables.$font-weight-medium;
    line-height: 20px;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    transition-duration: 175ms;
    transition-property: background, border-color, color;
    transition-timing-function: ease-in;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover {
        color: variables.$body-color;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        opacity: 0.6;
        cursor: default;
    }

    @include breakpoints.media-breakpoint-up(lg) {
        border-radius: 30px;
        font-size: 1.125rem;
        padding: 19px 36px;
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

//
// Alternate buttons
//

.btn-primary {
    background-color: variables.$brand;
    border-color: variables.$brand;
    color: variables.$black;

    &:hover,
    &:focus {
        background-color: variables.$brand-dark;
        border-color: variables.$brand-dark;
        color: variables.$black;
    }
}
