@use "../mixins/screen-reader";

//
// Screenreaders utility
//
.sr-only {
    @include screen-reader.sr-only();
}

.sr-only-focusable {
    @include screen-reader.sr-only-focusable();
}
