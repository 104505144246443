@use "../global/variables";
@use "../mixins";

//
// Difference page
//
#difference .site-header-push {
    display: none;
}

//
// Difference banner
//

.difference-banner {
    @include mixins.relative();
    background: url('/assets/images/difference-sm.jpg') no-repeat center / cover;
    display: flex;
    height: 635px;
    max-height: 90vh;
}

.difference-banner-content {
    @include mixins.relative();
    margin: 85px auto 0;
    text-align: center;
}

.difference-banner-content-title {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: variables.$font-weight-medium;
    margin-bottom: 20px;
}

.difference-banner-btn {
    @include mixins.absolute(top 100% left 50%);
    border: 3px solid variables.$white;
    border-radius: 50px;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.difference-banner-btn .btn {
    min-width: 290px;
}

.difference-banner-pin {
    @include mixins.relative();
    cursor: pointer;
    z-index: 90;
}

.difference-banner-pin-bg {
    fill: rgba(variables.$white, 0.17);
    transition: transform 175ms ease-in;

    .difference-banner-pin:hover & {
        transform: scale(1.3);
    }
}

.difference-banner-pin-icon {
    fill: variables.$brand;
}

.difference-banner-pin-tooltip {
    @include mixins.absolute();
    background: variables.$brand;
    color: variables.$gray-900;
    font-size: 0.8125rem;
    padding: 5px 15px;
    transform: translate(calc(-100% + 25px), -25px);
    white-space: nowrap;
    z-index: 100;
}

.difference-banner-pin-tooltip::before {
    @include mixins.absolute(top 100% right);
    @include mixins.size(0);
    border: 5px solid transparent;
    border-top-color: variables.$brand;
    border-right-color: variables.$brand;
    content: "";
    display: block;
}

@include mixins.media-breakpoint-down(sm) {
    .difference-banner-bg,
    .difference-banner-btn {
        display: none;
    }
}

@include mixins.media-breakpoint-up(sm) {
    .difference-banner {
        background-image: url('/assets/images/difference-md.jpg');
    }
}

@include mixins.media-breakpoint-up(md) {
    .difference-banner {
        @include mixins.relative();
        background: none;
        height: 950px;
    }

    .difference-banner-bg {
        @include mixins.absolute(top left);
        @include mixins.size(100%);
    }

    .difference-banner-content {
        @include mixins.relative();
        margin-top: 140px;
        pointer-events: none;
        width: 500px;
    }

    .difference-banner-content-title {
        margin-bottom: variables.$spacer-1;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .difference-banner {
        height: 820px;
    }

    .difference-banner::before {
        @include mixins.absolute(top left bottom);
        background: linear-gradient(to right, rgba(variables.$white, 0.7) 0%, rgba(variables.$white, 0.7) 50%, rgba(variables.$white, 0) 100%);
        content: "";
        pointer-events: none;
        width: 50%;
        z-index: 1;
    }

    .difference-banner-content {
        margin: auto 0;
        text-align: left;
        width: 290px;
        z-index: 2;
    }

    .difference-banner-content-title {
        font-size: 3.75rem;
    }

    .difference-banner-btn .btn {
        min-width: 350px;
    }
}

@include mixins.media-breakpoint-up(xl) {
    .difference-banner {
        height: 980px;
    }
}

//
// Difference banner pin transition
//

.difference-banner-pin-enter-active,
.difference-banner-pin-leave-active {
    transition: opacity 375ms ease-in;
}

.difference-banner-pin-enter-from,
.difference-banner-pin-leave-to {
    opacity: 0;
}

.difference-banner-pin-enter-to,
.difference-banner-pin-leave-from {
    opacity: 1;
}

//
// Difference banner modal
//

.difference-modal-panel {
    padding: 95px 115px;
}

.difference-modal-body {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.difference-modal-image {
    margin-top: variables.$spacer-1;
}

.difference-modal-image img,
.difference-modal-image video {
    @include mixins.img-fluid();
}

@include mixins.media-breakpoint-up(lg) {
    .difference-modal-panel {
        padding: 135px 155px;
    }

    .difference-modal-body {
        align-items: center;
        flex-direction: row;
        min-height: 307px;
        text-align: left;
    }

    .difference-modal-content {
        padding-left: 85px;
        order: 2;
    }

    .difference-modal-content .modal-title {
        line-height: 1.1;
        margin-bottom: 24px;
    }

    .difference-modal-image {
        flex-shrink: 0;
        margin-top: 0;
        order: 1;
        max-width: 315px;
    }
}

//
// Difference modal transition
//

.difference-modal-wrapper {
    @include mixins.relative();
    transition: height 275ms ease-in;
}

.difference-modal-leave-active {
    @include mixins.absolute();
    opacity: 1;
    transition: opacity 275ms ease-in;
}

.difference-modal-enter-active {
    opacity: 0;
    transition: opacity 275ms 275ms ease-in;
}

.difference-modal-enter-to {
    opacity: 1;
}

.difference-modal-leave-to {
    opacity: 0;
}

//
// Difference features
//

.difference-features {
    padding-top: variables.$spacer-1;
}

@include mixins.media-breakpoint-up(md) {
    .difference-features {
        display: none;
    }
}

//
// Difference controls
//

.difference-controls-grid {
    display: grid;
    row-gap: variables.$spacer-1;
    margin-top: -#{variables.$spacer-1};
}

.difference-controls-heading {
    margin-top: variables.$spacer-1;
}

.difference-controls-title {
    font-size: 1.875rem;
    margin-bottom: 0;
}

.difference-controls-automate {
    width: 226px;
    margin-top: 15px;
}

.difference-controls-content-text > :last-child {
    margin-bottom: 0;
}

.difference-controls-content-image {
    display: flex;
    justify-content: center;
    margin-top: variables.$spacer-1;
}

.difference-controls-content-remote {
    width: 45px;
}

.difference-controls-content-smart {
    width: 254px;
}

.difference-controls-content-crank {
    width: 48px;
}

.difference-controls-content-spring {
    width: 86px;
}

@include mixins.media-breakpoint-up(md) {
    .difference-controls-grid {
        column-gap: variables.$spacer-2;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-top: 0;
        row-gap: variables.$spacer-3;
    }

    .difference-controls-heading {
        margin: 0;
    }

    .difference-controls-content {
        display: flex;
        flex-direction: column;
    }

    .difference-controls-content-image {
        align-items: center;
        justify-content: flex-start;
        height: 124px;
        margin: 0 0 variables.$spacer-1;
        //height: 164px;
        order: 1;
    }

    .difference-controls-content-text {
        order: 2;
    }

    .difference-controls-content-remote {
        width: 30px;
    }

    .difference-controls-content-smart {
        width: 182px;
    }

    .difference-controls-content-crank {
        width: 33px;
    }

    .difference-controls-content-spring {
        width: 73px;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .difference-controls-grid {
        margin-top: variables.$spacer-3;
    }

    .difference-controls-title {
        font-size: 2.5rem;
    }

    .difference-controls-content-remote {
        width: 40px;
    }

    .difference-controls-content-smart {
        width: 244px;
    }

    .difference-controls-content-crank {
        width: 48px;
    }

    .difference-controls-content-spring {
        width: 88px;
    }
}

//
// Difference innovation
//

.difference-innovation {
    display: grid;
    row-gap: variables.$spacer-1;
}

.difference-innovation-intro .page-title {
    font-size: 2.5rem;
    line-height: 1.15;
    margin-bottom: variables.$spacer-1;
}

.difference-innovation-wind {
    font-size: 0.9375rem;
    padding: variables.$spacer-1 0;
}

.difference-innovation-wind-image {
    display: none;
}

@include mixins.media-breakpoint-up(md) {
    .difference-innovation {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: variables.$spacer-1;
    }

    .difference-innovation-intro .page-title {
        font-size: 3rem;
        text-align: left;
    }

    .difference-innovation-wind {
        display: flex;
        grid-column: auto / span 2;
        padding-bottom: 0;
    }

    .difference-innovation-wind-image {
        @include mixins.size(100px);
        align-items: center;
        background-color: rgba(variables.$gray-200, 0.3);
        border-radius: 50px;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        margin-right: variables.$spacer-1;
    }

    .difference-innovation-wind-image svg {
        width: 60px;
    }

    .difference-innovation-video {
        grid-column: auto / span 2;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .difference-innovation {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        row-gap: variables.$spacer-2;
        column-gap: 16px;
    }

    .difference-innovation-intro {
        grid-column: 1 / span 6;
    }

    .difference-innovation-intro .page-title {
        font-size: 3.75rem;
        margin-bottom: variables.$spacer-2;
    }

    .difference-innovation-image {
        grid-column: 8 / span 5;
        grid-row: 1 / span 2;
    }

    .difference-innovation-wind {
        align-self: end;
        grid-column: 1 / span 6;
        grid-row: 2 / span 2;
        padding: 0;
    }

    .difference-innovation-wind-image {
        @include mixins.size(140px);
        border-radius: 70px;
    }

    .difference-innovation-wind-image svg {
        width: 85px;
    }

    .difference-innovation-video {
        grid-column: auto / span 12;
    }
}

//
// Difference efficiency
//

.difference-efficiency {
    display: grid;
    row-gap: variables.$spacer-1;
    margin-bottom: variables.$spacer-2;
}

.difference-efficiency-content .page-title {
    font-size: 2.5rem;
    line-height: 1.15;
    margin-bottom: variables.$spacer-1;
}

@include mixins.media-breakpoint-up(md) {
    .difference-efficiency {
        column-gap: 25px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .difference-efficiency-content {
        order: 2;
    }

    .difference-efficiency-content .page-title {
        font-size: 3rem;
        text-align: left;
    }

    .difference-efficiency-image {
        order: 1;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .difference-efficiency {
        column-gap: 16px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        margin-bottom: variables.$spacer-3;
    }

    .difference-efficiency-content {
        grid-column: 7 / span 6;
    }

    .difference-efficiency-content .page-title {
        font-size: 3.75rem;
        margin-bottom: variables.$spacer-2;
    }

    .difference-efficiency-image {
        grid-column: 1 / span 5;
    }
}

//
// Difference range
//

.difference-range .page-title {
    font-size: 2.5rem;
    line-height: 1.15;
}

.difference-range-image {
    display: grid;
    gap: variables.$spacer-1;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

@include mixins.media-breakpoint-up(md) {
    .difference-range-item-image {
        display: block;
    }
}

@include mixins.media-breakpoint-up(lg) {
    .difference-range {
        display: grid;
        column-gap: 16px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .difference-range .page-title {
        font-size: 3.75rem;
        grid-column: 1 / span 3;
        text-align: left;
    }

    .difference-range .page-button {
        grid-column: 1 / span 3;
        margin-top: 0;
    }

    .difference-range-image {
        align-self: center;
        grid-column: 6 / span 6;
        grid-row: 1 / span 2;
    }
}
