@use "../global/variables";
@use "../mixins";

//
// Video player
//
.video-grid {
  display: grid;
  column-gap: 25px;
  row-gap: variables.$spacer-1;
  grid-template-columns: minmax(0, 1fr);
}


.video-item-thumb {
  background: no-repeat center center / cover;
  display: block;
  padding-bottom: 56.25%; //percentage(360 / 640)
}


.video-player {
  @include mixins.relative();
  padding-bottom: 56.25%; //percentage(360 / 640)
  width: 100%;
}

.video-player iframe {
  @include mixins.absolute(top left);
  @include mixins.size(100%);
}

@include mixins.media-breakpoint-up(md) {
  .video-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@include mixins.media-breakpoint-up(lg) {
  .video-grid {
    column-gap: 50px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: variables.$spacer-2;
  }
}

@include mixins.media-breakpoint-up(xl) {
  .video-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
