@use "../global/variables";
@use "../mixins";

//
// Product gallery component
//
.gallery {
  display: flex;
  flex-direction: column;
  row-gap: variables.$spacer-1;
}

.gallery-track {
  display: flex;
  align-items: center;
}

.gallery-image-wrapper {
  @include mixins.relative();
  padding-bottom: 90%;
  width: 100%;
}

.gallery-image {
  @include mixins.absolute(top left);
  background: no-repeat center center / contain;
  padding-bottom: 100%;
  width: 100%;
}

.gallery-thumbs {
  display: none;
  column-gap: variables.$spacer-1;
  justify-content: center;
  @include mixins.media-breakpoint-up(md) {
    display: flex;
  }
}

.gallery-thumb {
  @include mixins.size(94px);
  background: no-repeat center center / cover;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 175ms ease-in;

  &.is-hover {
    border-color: variables.$gray-900;
  }

  &.is-active {
    border-color: variables.$brand;
  }
}

.gallery-leave-active {
  opacity: 1;
  transition: opacity 275ms ease-in;
}

.gallery-enter-active {
  opacity: 0;
  transition: opacity 275ms 275ms ease-in;
}

.gallery-enter-to {
  opacity: 1;
}

.gallery-leave-to {
  opacity: 0;
}
