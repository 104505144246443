@use "../global/variables";
@use "../mixins";

//
// Foundation
//

html,
body {
    min-height: 100vh;
    overflow-x: hidden;
}

html {
    width: 100vw;
    min-width: 320px;
    max-width: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

strong, b {
    font-weight: variables.$font-weight-bold;
}

svg {
    fill: currentColor;
}

img {
    @include mixins.img-fluid();
}

table {
    width: 100%;
}

label {
    margin-bottom: 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    line-height: normal;
}

input[type="file"] {
    line-height: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#svg-definitions {
    @include mixins.absolute(top left -9999px);
    visibility: hidden;
}

#headlessui-portal-root {
    @include mixins.relative();
    z-index: 1000;
}
