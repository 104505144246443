@use "../global/variables";
@use "../mixins/breakpoints";

//
// Page component
//
.page-title {
    text-align: center;
}

.page-intro {
    margin: 0 auto variables.$spacer-2;
    text-align: center;
    max-width: 570px;
}

.page-section-dark,
.page-section-grey,
.page-section-light {
    padding-top: variables.$spacer-2;
    padding-bottom: variables.$spacer-2;
}

.page-section-visualiser {
    padding-bottom: variables.$spacer-2;
}

.page-section-dark + .page-section-dark,
.page-section-grey + .page-section-grey,
.page-section-light + .page-section-light {
    padding-top: 0;
}

.page-section-dark {
    background: variables.$gray-700;
    color: variables.$white;
}

.page-section-grey {
    background: variables.$gray-100;
}

.page-button {
    margin-top: variables.$spacer-2;
    text-align: center;
}

.page-button.left {
    text-align: left;
}

.page-button .btn {
    min-width: 290px;
}

@include breakpoints.media-breakpoint-up(md) {
    .page-intro {
        margin-bottom: variables.$spacer-3;
    }
}

@include breakpoints.media-breakpoint-up(lg) {
    .page-intro {
        max-width: 800px;
        margin-bottom: variables.$spacer-4;
    }

    .page-section-dark,
    .page-section-grey,
    .page-section-light {
        padding-top: variables.$spacer-4;
        padding-bottom: variables.$spacer-4;
    }

    .page-section-dark__heading, .page-section-grey__heading, .page-button__heading {
        padding-top: variables.$spacer-2;
    }

    .page-section-dark__heading .page-title, .page-section-grey__heading .page-title, .page-button__heading .page-title {
        margin-bottom: variables.$spacer-2;
    }

    .page-button {
        margin-top: variables.$spacer-3;
    }

    .page-button .btn {
        min-width: 350px;
    }
}
