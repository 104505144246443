@use "../global/variables";
@use "../mixins";

//
// Gallery section
//
.gallery-tabs {
  @include mixins.relative();
  border-bottom: 1px solid variables.$gray-400;
  display: flex;
  margin-bottom: variables.$spacer-1;
}

.gallery-tabs-item {
  color: variables.$body-color;
  flex: 0 0 50%;
  padding-bottom: 24px;
  text-align: center;
  width: 100%;
}

.gallery-tabs-underline {
  @include mixins.absolute(bottom);
  background: variables.$gray-600;
  height: 7px;
  pointer-events: none;
  transition-duration: 175ms;
  transition-property: left, width;
  transition-timing-function: ease-in;
}

.gallery-tabs-item:hover {
  color: variables.$body-color;
  text-decoration: none;
}

.gallery-grid {
  display: grid;
  gap: variables.$spacer-1;
}

.gallery-grid-item {
  @include mixins.relative();
  padding-bottom: 65%;
}

.gallery-grid-item-image {
  @include mixins.absolute(top left);
  @include mixins.size(100%);
  background: no-repeat center center / cover;
  display: block;
}

.gallery-modal-panel {
  padding: 95px 115px variables.$spacer-1;
  max-width: none;
}

.gallery-modal-content-info {
  display: flex;
  justify-content: space-between;
  margin-top: variables.$spacer-1;
}

@include mixins.media-breakpoint-up(md) {
  .gallery-grid {
    gap: 25px;
    grid-template-columns: repeat(3, 1fr);
  }

  .gallery-grid-item:nth-child(6n - 5),
  .gallery-grid-item:nth-child(6n - 1) {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .gallery-tabs {
    margin-bottom: variables.$spacer-2;
  }

  .gallery-grid {
    gap: 50px;
  }

  .gallery-grid-item {
    overflow: hidden;
  }

  .gallery-grid-item-image {
    transition: transform 175ms ease-in;
  }

  .gallery-grid-item-image:hover {
    transform: scale(1.07);
  }
}

//
// Gallery item transition
//

.gallery-item-enter-active {
  opacity: 0;
  transition: opacity 275ms ease-in;
}

.gallery-item-enter-to {
  opacity: 1;
}

//
// Gallery zoom transition
//

.gallery-modal-wrapper {
  @include mixins.relative();
}

.gallery-modal-leave-active {
  @include mixins.absolute();
  opacity: 1;
  transition: opacity 275ms ease-in;
}

.gallery-modal-enter-active {
  opacity: 0;
  transition: opacity 275ms 275ms ease-in;
}

.gallery-modal-enter-to {
  opacity: 1;
}

.gallery-modal-leave-to {
  opacity: 0;
}
